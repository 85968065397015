import { default as _91_46_46_46not_45found_93aQrqc1AQITMeta } from "C:/FE02/_work/190/s/Gallery/pages/[...not-found].vue?macro=true";
import { default as aboutRdhXqqRiVbMeta } from "C:/FE02/_work/190/s/Gallery/pages/about.vue?macro=true";
import { default as account_45createdylnTO6y8iEMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/account-created.vue?macro=true";
import { default as additional_45informationqP9C6dzHyTMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/additional-information.vue?macro=true";
import { default as addressesChMXttnzpeMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/addresses.vue?macro=true";
import { default as amendmentE9ag2JP3NNMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/amendment.vue?macro=true";
import { default as indexRe9oWzx8pDMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/bank-account/index.vue?macro=true";
import { default as requestedkjisOO26JnMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/bank-account/requested.vue?macro=true";
import { default as contract_45signingrfdcljVAAtMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/contract-signing.vue?macro=true";
import { default as document_45update7yLffsdG49Meta } from "C:/FE02/_work/190/s/Gallery/pages/account/document-update.vue?macro=true";
import { default as documents_45checkZk5QIjxlWfMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/documents-check.vue?macro=true";
import { default as indexYWA9TW50npMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/index.vue?macro=true";
import { default as requestednei5WcNzhXMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/requested.vue?macro=true";
import { default as upload_45documentsmJwv8A12RaMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/upload-documents.vue?macro=true";
import { default as personal_45datal8AP1m5CtWMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/personal-data.vue?macro=true";
import { default as phone_45number_45verification7HO2gQ1BDEMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/phone-number-verification.vue?macro=true";
import { default as indexornyG6NEaEMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/index.vue?macro=true";
import { default as requested2FRw1MDSjtMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/requested.vue?macro=true";
import { default as questionnairetJofCTkPu3Meta } from "C:/FE02/_work/190/s/Gallery/pages/account/questionnaire.vue?macro=true";
import { default as indexKWNOTlApvqMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/index.vue?macro=true";
import { default as requestedpMZ2bGs8j6Meta } from "C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/requested.vue?macro=true";
import { default as verificationbjzXXJZIvRMeta } from "C:/FE02/_work/190/s/Gallery/pages/account/verification.vue?macro=true";
import { default as annual_45reportsMSYuJeGf1YMeta } from "C:/FE02/_work/190/s/Gallery/pages/annual-reports.vue?macro=true";
import { default as bankidJ5ETexhtEGMeta } from "C:/FE02/_work/190/s/Gallery/pages/bankid.vue?macro=true";
import { default as contactvrxdXui3ZwMeta } from "C:/FE02/_work/190/s/Gallery/pages/contact.vue?macro=true";
import { default as contractual_45documentationGsf2lt1Sr9Meta } from "C:/FE02/_work/190/s/Gallery/pages/contractual-documentation.vue?macro=true";
import { default as _91id_93pxy2NQqIo6Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/[id].vue?macro=true";
import { default as _91id_93Hf2r8A1Mt1Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue?macro=true";
import { default as newoPoe6qSFLNMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue?macro=true";
import { default as indexyFo6rXLZa3Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/index.vue?macro=true";
import { default as my_45advertisementsruuP8tOMp8Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue?macro=true";
import { default as termstwEnnT4D28Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/terms.vue?macro=true";
import { default as _91id_93MFwOC5DVBEMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue?macro=true";
import { default as indext7w2QpE81XMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/index.vue?macro=true";
import { default as new3OnH27DqeuMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/new.vue?macro=true";
import { default as advertising_45spacexU8zs4FhLNMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space.vue?macro=true";
import { default as depositcTKXWGor3kMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/deposit.vue?macro=true";
import { default as index0Cx5eD6PPQMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/index.vue?macro=true";
import { default as purchase_45instructionsf15p5Zl2LyMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/purchase-instructions.vue?macro=true";
import { default as transactions_45historyqeaaRb8vqwMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/transactions-history.vue?macro=true";
import { default as confirmationYURBDEa7ZkMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue?macro=true";
import { default as index1NjH4L3IROMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/index.vue?macro=true";
import { default as financerYvKVOsceJMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/finance.vue?macro=true";
import { default as indexXeW19UN7a9Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue?macro=true";
import { default as unique_45exhibition_45alfons_45muchah6jaEj8AwCMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue?macro=true";
import { default as vouchersUU4eF5BzztMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue?macro=true";
import { default as gifts_45and_45benefitsAcQMCb0PRrMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits.vue?macro=true";
import { default as indexXDli08gek7Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/index.vue?macro=true";
import { default as indexmvPST9I9SDMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/index.vue?macro=true";
import { default as _91slug_935d9MXqj5SzMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue?macro=true";
import { default as indexqMWzcAhFHlMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/index.vue?macro=true";
import { default as investments_45offerEkh1ChrUptMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer.vue?macro=true";
import { default as my_45investmentsCnT48nId71Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/my-investments.vue?macro=true";
import { default as votingVrwpIYFRuEMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/voting.vue?macro=true";
import { default as investmentsqV9NeQ1ECvMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/investments.vue?macro=true";
import { default as add_45accountMbMrAmAyPFMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/add-account.vue?macro=true";
import { default as additional_45settingsrTevR5sosIMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/additional-settings.vue?macro=true";
import { default as indexOsqMEljzcbMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue?macro=true";
import { default as requestedksKGXqKkfCMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue?macro=true";
import { default as upload_45documentJxFrJNTyAIMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue?macro=true";
import { default as indexXaPPxh3Jm6Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue?macro=true";
import { default as delete_45accountrvsdRVzAb0Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/delete-account.vue?macro=true";
import { default as indexAje3JrPejtMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/index.vue?macro=true";
import { default as requestedVC04sMccFFMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/requested.vue?macro=true";
import { default as edit_45personal_45informationZvo1K3omWTMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/edit-personal-information.vue?macro=true";
import { default as email_45change5NU7DqoAPSMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-change.vue?macro=true";
import { default as email_45preferences6tpLXrvrUPMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-preferences.vue?macro=true";
import { default as czech_45pointYGu4zOQadWMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue?macro=true";
import { default as indexGVOXSswH64Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/index.vue?macro=true";
import { default as indexn51TtEXshhMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/index.vue?macro=true";
import { default as mailing_45address_45change8iAPlyr9e6Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/mailing-address-change.vue?macro=true";
import { default as indexQpMIZQreniMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/index.vue?macro=true";
import { default as requestedtw26F5yGXAMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/requested.vue?macro=true";
import { default as upload_45documentsmodO4Pi2njMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue?macro=true";
import { default as indexSQ7VGN3I0rMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue?macro=true";
import { default as requestedJa4At06dVcMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue?macro=true";
import { default as upload_45documentsm5ShJFWOkEMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue?macro=true";
import { default as additional_45informationE6TOiQOYjbMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue?macro=true";
import { default as indexEIv6VKTs92Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue?macro=true";
import { default as indexn66gSr4I5dMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue?macro=true";
import { default as requestedKZNo9rkQfMMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue?macro=true";
import { default as index0tGAv4pNOqMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue?macro=true";
import { default as requestedsiSD2hzSDlMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue?macro=true";
import { default as indexE490fWPUIpMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue?macro=true";
import { default as requested9x5zuBolhqMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue?macro=true";
import { default as personal_45data_45confirmationijZrFGohPhMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue?macro=true";
import { default as phone_45changewIaNyCebkoMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/phone-change.vue?macro=true";
import { default as indexD7fbAB0ALTMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/index.vue?macro=true";
import { default as password_45changeGc5RqYC2V7Meta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/password-change.vue?macro=true";
import { default as documentsSfPZiunTyqMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue?macro=true";
import { default as indexf3NdtKjiJOMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue?macro=true";
import { default as statements_45and_45confirmations7yGnUeU7PtMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue?macro=true";
import { default as profileJ5tspmnvsGMeta } from "C:/FE02/_work/190/s/Gallery/pages/dashboard/profile.vue?macro=true";
import { default as elementPcBPacOnZ8Meta } from "C:/FE02/_work/190/s/Gallery/pages/element.vue?macro=true";
import { default as email_45verificationdESaYMOrATMeta } from "C:/FE02/_work/190/s/Gallery/pages/external/email-verification.vue?macro=true";
import { default as impersonationxzmgAsM7bMMeta } from "C:/FE02/_work/190/s/Gallery/pages/external/impersonation.vue?macro=true";
import { default as loginil20RMIsc0Meta } from "C:/FE02/_work/190/s/Gallery/pages/external/login.vue?macro=true";
import { default as password_45reset6zzPuG7f8bMeta } from "C:/FE02/_work/190/s/Gallery/pages/external/password-reset.vue?macro=true";
import { default as _91slug_937JhzKBIZHtMeta } from "C:/FE02/_work/190/s/Gallery/pages/faq/[slug].vue?macro=true";
import { default as index01xtXXirymMeta } from "C:/FE02/_work/190/s/Gallery/pages/faq/index.vue?macro=true";
import { default as faqonnakNELnyMeta } from "C:/FE02/_work/190/s/Gallery/pages/faq.vue?macro=true";
import { default as forgotten_45passwordvEilXEYsOGMeta } from "C:/FE02/_work/190/s/Gallery/pages/forgotten-password.vue?macro=true";
import { default as how_45does_45it_45work0Z0wz2jDctMeta } from "C:/FE02/_work/190/s/Gallery/pages/how-does-it-work.vue?macro=true";
import { default as indexdclZUWUguIMeta } from "C:/FE02/_work/190/s/Gallery/pages/index.vue?macro=true";
import { default as _91slug_93EeasgGeDOyMeta } from "C:/FE02/_work/190/s/Gallery/pages/investments-offer/[slug].vue?macro=true";
import { default as indexM08sC2sGMLMeta } from "C:/FE02/_work/190/s/Gallery/pages/investments-offer/index.vue?macro=true";
import { default as loginvWxA1pTM5DMeta } from "C:/FE02/_work/190/s/Gallery/pages/login.vue?macro=true";
import { default as obligatory_45published_45informationMWEVfwWEISMeta } from "C:/FE02/_work/190/s/Gallery/pages/obligatory-published-information.vue?macro=true";
import { default as privacy_45settingsqTM3OEtjMuMeta } from "C:/FE02/_work/190/s/Gallery/pages/privacy-settings.vue?macro=true";
import { default as registrationsBGCHsdzPeMeta } from "C:/FE02/_work/190/s/Gallery/pages/registration.vue?macro=true";
import { default as securityRqik58c0YmMeta } from "C:/FE02/_work/190/s/Gallery/pages/security.vue?macro=true";
export default [
  {
    name: "notfound___cs___default",
    path: "/:notfound(.*)*",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "notfound___cs",
    path: "/cs/:notfound(.*)*",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/[...not-found].vue").then(m => m.default || m)
  },
  {
    name: "about___cs___default",
    path: "/o-nas",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs___default",
    path: "/ucet/ucet-vytvoren",
    meta: account_45createdylnTO6y8iEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-account-created___cs",
    path: "/cs/ucet/ucet-vytvoren",
    meta: account_45createdylnTO6y8iEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/account-created.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs___default",
    path: "/ucet/dalsi-informace",
    meta: additional_45informationqP9C6dzHyTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-additional-information___cs",
    path: "/cs/ucet/dalsi-informace",
    meta: additional_45informationqP9C6dzHyTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs___default",
    path: "/ucet/adresy",
    meta: addressesChMXttnzpeMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___cs",
    path: "/cs/ucet/adresy",
    meta: addressesChMXttnzpeMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs___default",
    path: "/ucet/dodatek",
    meta: amendmentE9ag2JP3NNMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-amendment___cs",
    path: "/cs/ucet/dodatek",
    meta: amendmentE9ag2JP3NNMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/amendment.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs___default",
    path: "/ucet/bankovni-ucet",
    meta: indexRe9oWzx8pDMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account___cs",
    path: "/cs/ucet/bankovni-ucet",
    meta: indexRe9oWzx8pDMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/bank-account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs___default",
    path: "/ucet/bankovni-ucet/overeni",
    meta: requestedkjisOO26JnMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-bank-account-requested___cs",
    path: "/cs/ucet/bankovni-ucet/overeni",
    meta: requestedkjisOO26JnMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/bank-account/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs___default",
    path: "/ucet/podpis-smlouvy",
    meta: contract_45signingrfdcljVAAtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-contract-signing___cs",
    path: "/cs/ucet/podpis-smlouvy",
    meta: contract_45signingrfdcljVAAtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/contract-signing.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs___default",
    path: "/ucet/aktualizace-dokladu",
    meta: document_45update7yLffsdG49Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-document-update___cs",
    path: "/cs/ucet/aktualizace-dokladu",
    meta: document_45update7yLffsdG49Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/document-update.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs___default",
    path: "/ucet/kontrola-dokladu",
    meta: documents_45checkZk5QIjxlWfMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-documents-check___cs",
    path: "/cs/ucet/kontrola-dokladu",
    meta: documents_45checkZk5QIjxlWfMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/documents-check.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs___default",
    path: "/ucet/overeni-identity",
    meta: indexYWA9TW50npMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification___cs",
    path: "/cs/ucet/overeni-identity",
    meta: indexYWA9TW50npMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs___default",
    path: "/ucet/overeni-identity/overeni",
    meta: requestednei5WcNzhXMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-requested___cs",
    path: "/cs/ucet/overeni-identity/overeni",
    meta: requestednei5WcNzhXMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs___default",
    path: "/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsmJwv8A12RaMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-identity-verification-upload-documents___cs",
    path: "/cs/ucet/overeni-identity/nahrat-doklady",
    meta: upload_45documentsmJwv8A12RaMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/identity-verification/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs___default",
    path: "/ucet/osobni-udaje",
    meta: personal_45datal8AP1m5CtWMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data___cs",
    path: "/cs/ucet/osobni-udaje",
    meta: personal_45datal8AP1m5CtWMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs___default",
    path: "/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verification7HO2gQ1BDEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-phone-number-verification___cs",
    path: "/cs/ucet/overeni-telefonniho-cisla",
    meta: phone_45number_45verification7HO2gQ1BDEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/phone-number-verification.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs___default",
    path: "/ucet/politicky-exponovana-osoba",
    meta: indexornyG6NEaEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person___cs",
    path: "/cs/ucet/politicky-exponovana-osoba",
    meta: indexornyG6NEaEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs___default",
    path: "/ucet/politicky-exponovana-osoba/overeni",
    meta: requested2FRw1MDSjtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-politically-exposed-person-requested___cs",
    path: "/cs/ucet/politicky-exponovana-osoba/overeni",
    meta: requested2FRw1MDSjtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs___default",
    path: "/ucet/dotaznik",
    meta: questionnairetJofCTkPu3Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-questionnaire___cs",
    path: "/cs/ucet/dotaznik",
    meta: questionnairetJofCTkPu3Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/questionnaire.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu",
    meta: indexKWNOTlApvqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu",
    meta: indexKWNOTlApvqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs___default",
    path: "/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedpMZ2bGs8j6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-source-of-income-statement-requested___cs",
    path: "/cs/ucet/potvrzeni-zdroje-prijmu/overeni",
    meta: requestedpMZ2bGs8j6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/source-of-income-statement/requested.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs___default",
    path: "/ucet/verifikace",
    meta: verificationbjzXXJZIvRMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "account-verification___cs",
    path: "/cs/ucet/verifikace",
    meta: verificationbjzXXJZIvRMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/account/verification.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs___default",
    path: "/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "annual-reports___cs",
    path: "/cs/vyrocni-zpravy",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/annual-reports.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs___default",
    path: "/bankid",
    meta: bankidJ5ETexhtEGMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "bankid___cs",
    path: "/cs/bankid",
    meta: bankidJ5ETexhtEGMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/bankid.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs___default",
    path: "/kontakt",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/kontakt",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs___default",
    path: "/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: "contractual-documentation___cs",
    path: "/cs/smluvni-dokumentace",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/contractual-documentation.vue").then(m => m.default || m)
  },
  {
    name: advertising_45spacexU8zs4FhLNMeta?.name,
    path: "/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs___default",
    path: ":id()",
    meta: _91id_93pxy2NQqIo6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs___default",
    path: "inzerat/:id()",
    meta: _91id_93Hf2r8A1Mt1Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs___default",
    path: "inzerat/novy",
    meta: newoPoe6qSFLNMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs___default",
    path: "/souhrn/inzertni-plocha",
    meta: indexyFo6rXLZa3Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs___default",
    path: "moje-inzeraty",
    meta: my_45advertisementsruuP8tOMp8Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs___default",
    path: "podminky",
    meta: termstwEnnT4D28Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs___default",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93MFwOC5DVBEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs___default",
    path: "pokyny-k-prevodu",
    meta: indext7w2QpE81XMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs___default",
    path: "pokyny-k-prevodu/novy",
    meta: new3OnH27DqeuMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: advertising_45spacexU8zs4FhLNMeta?.name,
    path: "/cs/souhrn/inzertni-plocha",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-advertising-space-id___cs",
    path: ":id()",
    meta: _91id_93pxy2NQqIo6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-id___cs",
    path: "inzerat/:id()",
    meta: _91id_93Hf2r8A1Mt1Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-advertisement-new___cs",
    path: "inzerat/novy",
    meta: newoPoe6qSFLNMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/advertisement/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space___cs",
    path: "/cs/souhrn/inzertni-plocha",
    meta: indexyFo6rXLZa3Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-my-advertisements___cs",
    path: "moje-inzeraty",
    meta: my_45advertisementsruuP8tOMp8Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/my-advertisements.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-terms___cs",
    path: "podminky",
    meta: termstwEnnT4D28Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/terms.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-id___cs",
    path: "pokyny-k-prevodu/:id()",
    meta: _91id_93MFwOC5DVBEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades___cs",
    path: "pokyny-k-prevodu",
    meta: indext7w2QpE81XMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-advertising-space-trades-new___cs",
    path: "pokyny-k-prevodu/novy",
    meta: new3OnH27DqeuMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/advertising-space/trades/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financerYvKVOsceJMeta?.name,
    path: "/souhrn/penize",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs___default",
    path: "vklad",
    meta: depositcTKXWGor3kMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs___default",
    path: "/souhrn/penize",
    meta: index0Cx5eD6PPQMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs___default",
    path: "pokyny",
    meta: purchase_45instructionsf15p5Zl2LyMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs___default",
    path: "transakcni-historie",
    meta: transactions_45historyqeaaRb8vqwMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs___default",
    path: "vyber/potvrzeni",
    meta: confirmationYURBDEa7ZkMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs___default",
    path: "vyber",
    meta: index1NjH4L3IROMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financerYvKVOsceJMeta?.name,
    path: "/cs/souhrn/penize",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-finance-deposit___cs",
    path: "vklad",
    meta: depositcTKXWGor3kMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/deposit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance___cs",
    path: "/cs/souhrn/penize",
    meta: index0Cx5eD6PPQMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-purchase-instructions___cs",
    path: "pokyny",
    meta: purchase_45instructionsf15p5Zl2LyMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/purchase-instructions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-transactions-history___cs",
    path: "transakcni-historie",
    meta: transactions_45historyqeaaRb8vqwMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/transactions-history.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal-confirmation___cs",
    path: "vyber/potvrzeni",
    meta: confirmationYURBDEa7ZkMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-finance-withdrawal___cs",
    path: "vyber",
    meta: index1NjH4L3IROMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/finance/withdrawal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsAcQMCb0PRrMeta?.name,
    path: "/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs___default",
    path: "",
    meta: indexXeW19UN7a9Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs___default",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchah6jaEj8AwCMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs___default",
    path: "darkove-poukazy",
    meta: vouchersUU4eF5BzztMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gifts_45and_45benefitsAcQMCb0PRrMeta?.name,
    path: "/cs/souhrn/darky-a-vyhody",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-gifts-and-benefits___cs",
    path: "",
    meta: indexXeW19UN7a9Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-unique-exhibition-alfons-mucha___cs",
    path: "unikatni-vystava-alfons-mucha",
    meta: unique_45exhibition_45alfons_45muchah6jaEj8AwCMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/unique-exhibition-alfons-mucha.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-gifts-and-benefits-vouchers___cs",
    path: "darkove-poukazy",
    meta: vouchersUU4eF5BzztMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/gifts-and-benefits/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard___cs___default",
    path: "/souhrn",
    meta: indexXDli08gek7Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___cs",
    path: "/cs/souhrn",
    meta: indexXDli08gek7Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: investmentsqV9NeQ1ECvMeta?.name,
    path: "/souhrn/investice",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs___default",
    path: "",
    meta: indexmvPST9I9SDMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerEkh1ChrUptMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs___default",
    path: "/souhrn/investice/:slug()",
    meta: _91slug_935d9MXqj5SzMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs___default",
    path: "/souhrn/investice/nabidka-investic",
    meta: indexqMWzcAhFHlMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs___default",
    path: "moje-investice",
    meta: my_45investmentsCnT48nId71Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs___default",
    path: "hlasovani",
    meta: votingVrwpIYFRuEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: investmentsqV9NeQ1ECvMeta?.name,
    path: "/cs/souhrn/investice",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments___cs",
    path: "",
    meta: indexmvPST9I9SDMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/index.vue").then(m => m.default || m)
  },
  {
    name: investments_45offerEkh1ChrUptMeta?.name,
    path: "nabidka-investic",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-investments-investments-offer-slug___cs",
    path: "/cs/souhrn/investice/:slug()",
    meta: _91slug_935d9MXqj5SzMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-investments-offer___cs",
    path: "/cs/souhrn/investice/nabidka-investic",
    meta: indexqMWzcAhFHlMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/investments-offer/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-investments-my-investments___cs",
    path: "moje-investice",
    meta: my_45investmentsCnT48nId71Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/my-investments.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-investments-voting___cs",
    path: "hlasovani",
    meta: votingVrwpIYFRuEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/investments/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileJ5tspmnvsGMeta?.name,
    path: "/dashboard/profile",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs___default",
    path: "/souhrn/profil/pridat-ucet",
    meta: add_45accountMbMrAmAyPFMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs___default",
    path: "/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsrTevR5sosIMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat",
    meta: indexOsqMEljzcbMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedksKGXqKkfCMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs___default",
    path: "/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentJxFrJNTyAIMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs___default",
    path: "/souhrn/profil/bankovni-ucty",
    meta: indexXaPPxh3Jm6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs___default",
    path: "/souhrn/profil/zruseni-uctu",
    meta: delete_45accountrvsdRVzAb0Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu",
    meta: indexAje3JrPejtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs___default",
    path: "/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedVC04sMccFFMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs___default",
    path: "/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationZvo1K3omWTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs___default",
    path: "/souhrn/profil/zmena-e-mailu",
    meta: email_45change5NU7DqoAPSMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs___default",
    path: "/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferences6tpLXrvrUPMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs___default",
    path: "/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointYGu4zOQadWMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs___default",
    path: "/souhrn/profil/overeni-totoznosti",
    meta: indexGVOXSswH64Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs___default",
    path: "/souhrn/profil",
    meta: indexn51TtEXshhMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs___default",
    path: "/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45change8iAPlyr9e6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs___default",
    path: "/souhrn/profil/zmena-jmena",
    meta: indexQpMIZQreniMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs___default",
    path: "/souhrn/profil/zmena-jmena/overeni",
    meta: requestedtw26F5yGXAMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsmodO4Pi2njMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy",
    meta: indexSQ7VGN3I0rMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedJa4At06dVcMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs___default",
    path: "/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsm5ShJFWOkEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationijZrFGohPhMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationE6TOiQOYjbMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs___default",
    path: "/souhrn/profil/aktualizace-udaju",
    meta: indexEIv6VKTs92Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexn66gSr4I5dMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedKZNo9rkQfMMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: index0tGAv4pNOqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedsiSD2hzSDlMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexE490fWPUIpMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs___default",
    path: "/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requested9x5zuBolhqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs___default",
    path: "/souhrn/profil/zmena-telefonu",
    meta: phone_45changewIaNyCebkoMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs___default",
    path: "/souhrn/profil/bezpecnost",
    meta: indexD7fbAB0ALTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs___default",
    path: "/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeGc5RqYC2V7Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentsSfPZiunTyqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty",
    meta: indexf3NdtKjiJOMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs___default",
    path: "/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmations7yGnUeU7PtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileJ5tspmnvsGMeta?.name,
    path: "/cs/dashboard/profile",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-add-account___cs",
    path: "/cs/souhrn/profil/pridat-ucet",
    meta: add_45accountMbMrAmAyPFMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/add-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-additional-settings___cs",
    path: "/cs/souhrn/profil/dalsi-nastaveni",
    meta: additional_45settingsrTevR5sosIMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/additional-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat",
    meta: indexOsqMEljzcbMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-requested___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/overeni",
    meta: requestedksKGXqKkfCMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts-add-upload-document___cs",
    path: "/cs/souhrn/profil/bankovni-ucty/pridat/nahrat-doklad",
    meta: upload_45documentJxFrJNTyAIMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/add/upload-document.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-bank-accounts___cs",
    path: "/cs/souhrn/profil/bankovni-ucty",
    meta: indexXaPPxh3Jm6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-delete-account___cs",
    path: "/cs/souhrn/profil/zruseni-uctu",
    meta: delete_45accountrvsdRVzAb0Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu",
    meta: indexAje3JrPejtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-document-update-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-dokladu/overeni",
    meta: requestedVC04sMccFFMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/document-update/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-edit-personal-information___cs",
    path: "/cs/souhrn/profil/uprava-osobnich-udaju",
    meta: edit_45personal_45informationZvo1K3omWTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/edit-personal-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-change___cs",
    path: "/cs/souhrn/profil/zmena-e-mailu",
    meta: email_45change5NU7DqoAPSMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-email-preferences___cs",
    path: "/cs/souhrn/profil/e-mailova-sdeleni",
    meta: email_45preferences6tpLXrvrUPMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification-czech-point___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti/czech-point",
    meta: czech_45pointYGu4zOQadWMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/czech-point.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-identity-verification___cs",
    path: "/cs/souhrn/profil/overeni-totoznosti",
    meta: indexGVOXSswH64Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/identity-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___cs",
    path: "/cs/souhrn/profil",
    meta: indexn51TtEXshhMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-mailing-address-change___cs",
    path: "/cs/souhrn/profil/zmena-korespondencni-adresy",
    meta: mailing_45address_45change8iAPlyr9e6Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/mailing-address-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change___cs",
    path: "/cs/souhrn/profil/zmena-jmena",
    meta: indexQpMIZQreniMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-jmena/overeni",
    meta: requestedtw26F5yGXAMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-name-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-jmena/nahrat-doklady",
    meta: upload_45documentsmodO4Pi2njMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/name-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy",
    meta: indexSQ7VGN3I0rMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-requested___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/overeni",
    meta: requestedJa4At06dVcMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-permanent-address-change-upload-documents___cs",
    path: "/cs/souhrn/profil/zmena-travale-adresy/nahrat-doklady",
    meta: upload_45documentsm5ShJFWOkEMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/permanent-address-change/upload-documents.vue").then(m => m.default || m)
  },
  {
    name: personal_45data_45confirmationijZrFGohPhMeta?.name,
    path: "personal-data-confirmation",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-personal-data-confirmation-additional-information___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/dalsi-informace",
    meta: additional_45informationE6TOiQOYjbMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/additional-information.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju",
    meta: indexEIv6VKTs92Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument",
    meta: indexn66gSr4I5dMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-new-document-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/novy-dokument/overeni",
    meta: requestedKZNo9rkQfMMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/new-document/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba",
    meta: index0tGAv4pNOqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-politically-exposed-person-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/politicky-exponovana-osoba/overeni",
    meta: requestedsiSD2hzSDlMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/politically-exposed-person/requested.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu",
    meta: indexE490fWPUIpMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-personal-data-confirmation-source-of-income-statement-requested___cs",
    path: "/cs/souhrn/profil/aktualizace-udaju/potvrzeni-zdroje-prijmu/overeni",
    meta: requested9x5zuBolhqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/personal-data-confirmation/source-of-income-statement/requested.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-phone-change___cs",
    path: "/cs/souhrn/profil/zmena-telefonu",
    meta: phone_45changewIaNyCebkoMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/phone-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security___cs",
    path: "/cs/souhrn/profil/bezpecnost",
    meta: indexD7fbAB0ALTMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-security-password-change___cs",
    path: "/cs/souhrn/profil/bezpecnost/zmena-hesla",
    meta: password_45changeGc5RqYC2V7Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/security/password-change.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/dokumenty",
    meta: documentsSfPZiunTyqMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/documents.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty",
    meta: indexf3NdtKjiJOMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-statements-and-documents-statements-and-confirmations___cs",
    path: "/cs/souhrn/profil/vypisy-a-dokumenty/vypisy-a-potvrzeni",
    meta: statements_45and_45confirmations7yGnUeU7PtMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/dashboard/profile/statements-and-documents/statements-and-confirmations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "element___cs___default",
    path: "/element",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "element___cs",
    path: "/cs/element",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/element.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs___default",
    path: "/external/email-verification",
    meta: email_45verificationdESaYMOrATMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-email-verification___cs",
    path: "/cs/external/email-verification",
    meta: email_45verificationdESaYMOrATMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/email-verification.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs___default",
    path: "/external/impersonation",
    meta: impersonationxzmgAsM7bMMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-impersonation___cs",
    path: "/cs/external/impersonation",
    meta: impersonationxzmgAsM7bMMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/impersonation.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs___default",
    path: "/external/login",
    meta: loginil20RMIsc0Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-login___cs",
    path: "/cs/external/login",
    meta: loginil20RMIsc0Meta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/login.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs___default",
    path: "/external/password-reset",
    meta: password_45reset6zzPuG7f8bMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "external-password-reset___cs",
    path: "/cs/external/password-reset",
    meta: password_45reset6zzPuG7f8bMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/external/password-reset.vue").then(m => m.default || m)
  },
  {
    name: faqonnakNELnyMeta?.name,
    path: "/faq",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs___default",
    path: ":slug()",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs___default",
    path: "",
    meta: index01xtXXirymMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqonnakNELnyMeta?.name,
    path: "/cs/faq",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "faq-slug___cs",
    path: ":slug()",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq___cs",
    path: "",
    meta: index01xtXXirymMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/faq/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgotten-password___cs___default",
    path: "/zapomenute-heslo",
    meta: forgotten_45passwordvEilXEYsOGMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "forgotten-password___cs",
    path: "/cs/zapomenute-heslo",
    meta: forgotten_45passwordvEilXEYsOGMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs___default",
    path: "/jak-to-funguje",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "how-does-it-work___cs",
    path: "/cs/jak-to-funguje",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/how-does-it-work.vue").then(m => m.default || m)
  },
  {
    name: "index___cs___default",
    path: "/",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs___default",
    path: "/nabidka-investic/:slug()",
    meta: _91slug_93EeasgGeDOyMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer-slug___cs",
    path: "/cs/nabidka-investic/:slug()",
    meta: _91slug_93EeasgGeDOyMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/investments-offer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs___default",
    path: "/nabidka-investic",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "investments-offer___cs",
    path: "/cs/nabidka-investic",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/investments-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs___default",
    path: "/prihlasit-se",
    meta: loginvWxA1pTM5DMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/cs/prihlasit-se",
    meta: loginvWxA1pTM5DMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs___default",
    path: "/povinne-informace",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "obligatory-published-information___cs",
    path: "/cs/povinne-informace",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/obligatory-published-information.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs___default",
    path: "/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "privacy-settings___cs",
    path: "/cs/nastaveni-soukromi",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/privacy-settings.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs___default",
    path: "/registrace",
    meta: registrationsBGCHsdzPeMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___cs",
    path: "/cs/registrace",
    meta: registrationsBGCHsdzPeMeta || {},
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "security___cs___default",
    path: "/bezpecnost",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___cs",
    path: "/cs/bezpecnost",
    component: () => import("C:/FE02/_work/190/s/Gallery/pages/security.vue").then(m => m.default || m)
  }
]