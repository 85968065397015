import { czechValidator } from './czech';
import { polishValidator } from './polish';
import { Validator } from './types';

const _validators: {
  [key in 'CZ' | 'PL' | 'SK']: Validator | undefined;
} = {
  CZ: undefined,
  PL: undefined,
  SK: undefined,
};

export const registerValidator = (nationality: 'CZ' | 'PL' | 'SK', validator: Validator) => {
  _validators[nationality] = validator;
};

export const registerImplementations = () => {
  _validators.CZ = czechValidator;
  _validators.SK = czechValidator;
  _validators.PL = polishValidator;
};

export const getValidator = (nationality: 'CZ' | 'PL' | 'SK'): Validator => {
  const validator = _validators[nationality];
  if (!validator) {
    throw `Provider for nationality '${nationality}' not implemented.`;
  }

  return validator;
};
