// @ts-nocheck
export default defineNuxtPlugin(() => {
  // const {
  //   public: { gtmCode },
  // } = useRuntimeConfig();
  // window.dataLayer = window.dataLayer || [];
  // function gtag() {
  //   // eslint-disable-next-line prefer-rest-params
  //   window.dataLayer.push(arguments);
  // }
  // const hasSeenCookies = !!(
  //   document.cookie
  //     .split('; ')
  //     .find((cookie) => cookie.includes('cookies_consent_given'))
  //     ?.split('=')[1] ?? false
  // );
  // if (!hasSeenCookies) {
  //   gtag('consent', 'default', {
  //     personalisation_storage: 'denied',
  //     ad_storage: 'denied',
  //     analytics_storage: 'denied',
  //     ad_user_data: 'denied',
  //     ad_personalization: 'denied',
  //   });
  // }
  // window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  // const scripts = document.getElementsByTagName('script')[0];
  // const script = document.createElement('script');
  // script.async = true;
  // script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmCode}`;
  // scripts.parentNode.insertBefore(script, scripts);
});
