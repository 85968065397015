export default defineNuxtRouteMiddleware((_, to) => {
  if (import.meta.server) {
    return;
  }

  const authStore = useAuthStore();

  if (((to.name as string | null | undefined) || '').includes('dashboard')) {
    authStore.createVisibilityChangedEvent();
  } else {
    authStore.hydrate();
  }
});
