export enum InstrumentState {
  InPreparation,
  BeingSubscribed,
  Subscribed,
  Hidden,
  SubscriptionUnsuccessful,
  EvaluatingResults,
  Sold,
  PartlySold,
}
