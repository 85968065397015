import { defineStore } from 'pinia';
import { useErrorsStore } from './error';
import { useAuthStore } from './auth';
import { type Vote } from '~/models/surveys/vote';
import { VoteAnswer } from '~/models/surveys';
import { type SimpleStatusModel } from '~/models/arbitrary';

export const useSurveysStore = defineStore('surveys', {
  state: () => ({
    loadingVotes: true,
    votes: [] as Vote[],
  }),
  actions: {
    async getClientSurveys({ force = false }: { force?: boolean }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const { compareDesc } = useDateFns();

      if (!this.loadingVotes && !force) {
        return;
      }

      const votes = await fetch<Vote[]>(`/survey/client/${authStore.user.Id}`);

      this.votes = votes.sort((a, b) => compareDesc(new Date(a.CreatedOn), new Date(b.CreatedOn)));
      this.loadingVotes = false;
    },
    async vote({ voteId, answer }: { voteId: string; answer: VoteAnswer }) {
      const fetch = useApiFetch();
      const errorsStore = useErrorsStore();

      const result = await fetch<SimpleStatusModel>(`/survey/vote/${voteId}/${answer}`, {
        method: 'POST',
      });

      if (!result.Status) {
        errorsStore.showErrors({ errors: ['server-validations.GENERAL'] });
      }

      return result;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSurveysStore, import.meta.hot));
}
