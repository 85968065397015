import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VCalendar, {
    locales: {
      cs: {
        masks: {
          input: 'DD.MM.YYYY',
        },
      },
    },
  });
});
