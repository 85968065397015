import { defineStore } from 'pinia';
import { Product } from '~/models/arbitrary';
import { useApiFetch } from '~/composables/useApiFetch';
import type { RouteLocationNormalized } from '~/.nuxt/vue-router-stub';
import type { GlobalNotification, SimpleStringModel } from '~/models/arbitrary';

export type MobileMenuSection = 'accounts' | 'investments' | 'advertising' | 'transactions';
const DEFAULT_OPENED_MENU_SECTION: MobileMenuSection = 'accounts' as const;

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    headerHeight: 0,
    globalNotification: null as GlobalNotification | null,
    lastLoggedUser: undefined as string | undefined,
    afterLoginPage: undefined as RouteLocationNormalized | undefined,
    openedMobileMenuSection: DEFAULT_OPENED_MENU_SECTION as MobileMenuSection | null,
  }),
  actions: {
    async getGlobalNotification() {
      const fetch = useApiFetch();

      try {
        const notificationResult = await fetch<SimpleStringModel>(
          '/staticdata/masternotificationconfig',
        );

        if (!notificationResult.Result) {
          return;
        }

        const notifications = JSON.parse(notificationResult.Result) as GlobalNotification[];
        const notification = notifications.find(
          (notification) => notification.product === Product.Gallery,
        );

        if (!notification) {
          return;
        }

        this.globalNotification = notification;
      } catch (error) {
        // ignored
      }
    },
    setAfterLoginPage({ page }: { page: RouteLocationNormalized | undefined }) {
      this.afterLoginPage = page;
    },
    setOpenedMobileMenuSection(section: MobileMenuSection | null) {
      this.openedMobileMenuSection = section;
    },
    setDefaultOpenedMenuSection() {
      this.openedMobileMenuSection = DEFAULT_OPENED_MENU_SECTION;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot));
}
