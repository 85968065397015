import slugify from 'slugify';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      slugify: (string: string) => {
        return slugify(string, { lower: true });
      },
    },
  };
});
