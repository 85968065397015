import { stringify } from 'qs';
import { defineStore } from 'pinia';
import { useClientStore } from './clients';
import { useAuthStore } from './auth';
import {
  OfferState,
  TradeState,
  type Trade,
  type TradeOffer,
  FeePayer,
  type SearchFilter,
  type PagedTradeOffer,
} from '~/models/advertising-space';
import { TradeDirection } from '~/models/transactions';
import type { SimpleStatusModel, LinkModel } from '~/models/arbitrary';
import type { GeneralDocument } from '~/models/documents';
import type { Instrument } from '~/models/instruments';

type CreateTradeOfferPayload = Pick<
  TradeOffer,
  | 'Price'
  | 'Quantity'
  | 'Direction'
  | 'ContactName'
  | 'ContactEmail'
  | 'ContactPhone'
  | 'PublicNote'
> & { Instrument: Pick<Instrument, 'Id' | 'Name'> } & { Id?: TradeOffer['Id'] };
type TradeOffersCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: TradeOffer[]; totalCount: number };
};

export const useAdvertisingStore = defineStore('advertising', {
  state: () => ({
    loadingTradeOffers: true,
    tradeOffersCache: {
      cs: { loaded: false, data: [], totalCount: 0 },
      en: { loaded: false, data: [], totalCount: 0 },
    } as TradeOffersCache,
    loadingMyTradeOffers: true,
    myTradeOffersCache: {
      cs: { loaded: false, data: [], totalCount: 0 },
      en: { loaded: false, data: [], totalCount: 0 },
    } as TradeOffersCache,
    loadingTrades: true,
    trades: [] as Trade[],
  }),
  getters: {
    tradeOffers: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.tradeOffersCache[language].loaded) {
        return [
          state.tradeOffersCache[language].data,
          state.tradeOffersCache[language].totalCount,
        ] as [TradeOffer[], number];
      }

      return [[], 0] as [TradeOffer[], number];
    },
    myTradeOffers: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.myTradeOffersCache[language].loaded) {
        return state.myTradeOffersCache[language].data;
      }

      return [];
    },
    sellingTrades: (state) => {
      if (state.loadingTrades) {
        return [];
      }

      return state.trades.filter(
        (trade) =>
          trade.Direction === TradeDirection.Sell && trade.State !== TradeState.RejectedBySeller,
      );
    },
    waitingForBuyerSignatureSellingTrades(): Trade[] {
      return this.sellingTrades.filter(
        (trade) => trade.State === TradeState.WaitingForBuyerSignature,
      );
    },
    buyingTrades: (state) => {
      if (state.loadingTrades) {
        return [];
      }

      return state.trades.filter(
        (trade) =>
          trade.Direction === TradeDirection.Buy &&
          trade.State === TradeState.WaitingForBuyerSignature,
      );
    },
  },
  actions: {
    async getTermsAndConditionsDownloadLink() {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      const document = await fetch<GeneralDocument>(
        `/documents/generaldocument/${language}/SecondaryTradeConditions`,
      );
      return await fetch<LinkModel>(`/documents/generaldocument/link/${document.Id}`);
    },
    async acceptTerms() {
      const clientStore = useClientStore();

      await clientStore.update({ AgreedGallerySecTradeTerms: true });
    },
    async rejectTerms() {
      const clientStore = useClientStore();

      await clientStore.update({ AgreedGallerySecTradeTerms: false });
    },
    async getTradeOffers(filter: Omit<SearchFilter, 'ClientId' | 'Language'>) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      this.loadingTradeOffers = true;

      const response = await fetch<PagedTradeOffer>('/advertising/search', {
        method: 'POST',
        body: {
          ...filter,
          Language: language,
        } as SearchFilter,
      });

      this.tradeOffersCache[language] = {
        loaded: true,
        data: response.SecondaryTradeOffers,
        totalCount: response.TotalCount,
      };
      this.loadingTradeOffers = false;

      return [response.SecondaryTradeOffers, response.TotalCount] as [TradeOffer[], number];
    },
    async getTradeOffer({ id }: { id: string }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      return await fetch<TradeOffer>(
        `/advertising/${id}${stringify({ language }, { addQueryPrefix: true })}`,
      );
    },
    async getMyTradeOffers({ force = false }: { force?: boolean } = {}) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (this.myTradeOffersCache[language].loaded && !force) {
        return this.myTradeOffersCache[language].data;
      }

      this.loadingMyTradeOffers = true;

      const response = await fetch<PagedTradeOffer>('/advertising/search', {
        method: 'POST',
        body: {
          Page: 1,
          PageSize: 50_000,
          SortBy: 'CreatedOn',
          SortHow: 'Desc',
          ClientId: authStore.user.Id,
          Language: language,
        } as SearchFilter,
      });

      this.myTradeOffersCache[language] = {
        loaded: true,
        data: response.SecondaryTradeOffers,
        totalCount: response.TotalCount,
      };
      this.loadingMyTradeOffers = false;
    },
    async createOrUpdateTradeOffer({ offer }: { offer: CreateTradeOfferPayload }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      const { Status: status, Errors: errors } = await fetch<SimpleStatusModel>(
        offer.Id ? '/advertising/update' : '/advertising/create',
        {
          method: offer.Id ? 'PUT' : 'POST',
          body: {
            ...(offer.Id ? { Id: offer.Id } : {}),
            Name: offer.Instrument.Name,
            ClientId: authStore.user.Id,
            Price: offer.Price,
            Quantity: offer.Quantity,
            PublicNote: offer.PublicNote,
            ContactPhone: offer.ContactPhone,
            ContactName: offer.ContactName,
            ContactEmail: offer.ContactEmail,
            OfferState: OfferState.Active,
            Direction: offer.Direction,
            Instrument: {
              Id: offer.Instrument.Id,
            },
          },
        },
      );
      if (!status) {
        return {
          Status: false,
          Errors:
            errors.length > 0
              ? errors.map((error) => `server-validations.${error}`)
              : ['server-validations.GENERAL'],
        } as SimpleStatusModel;
      }

      return {
        Status: true,
      } as SimpleStatusModel;
    },
    async changeState({ id, state }: { id: string; state: OfferState }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      const tradeOffer = this.myTradeOffers.find((tradeOffer) => tradeOffer.Id === id)!;
      const response = await fetch<SimpleStatusModel>('/advertising/update', {
        method: 'PUT',
        body: {
          ...tradeOffer,
          OfferState: state,
        },
      });

      if (response.Status) {
        let cachedTradeOffer = this.tradeOffersCache[language].data.find(
          (tradeOffer) => tradeOffer.Id === id,
        );
        if (cachedTradeOffer) {
          cachedTradeOffer.OfferState = state;
        }

        cachedTradeOffer = this.myTradeOffersCache[language].data.find(
          (tradeOffer) => tradeOffer.Id === id,
        );
        if (cachedTradeOffer) {
          cachedTradeOffer.OfferState = state;
        }
      }

      return response;
    },
    async deleteOffer({ id }: { id: string }) {
      const fetch = useApiFetch();

      const tradeOffer = this.myTradeOffers.find((tradeOffer) => tradeOffer.Id === id)!;
      return await fetch<SimpleStatusModel>('/advertising/update', {
        method: 'PUT',
        body: {
          ...tradeOffer,
          OfferState: OfferState.Deleted,
        },
      });
    },
    async checkIdentifier({ identifier }: { identifier: string }) {
      const fetch = useApiFetch();

      return await fetch<SimpleStatusModel>(`/advertising/galleryuniquecode/check/${identifier}`);
    },
    async canCreateTrade(transfer: {
      price: number;
      quantity: number;
      instrumentId: string;
      identifier: string;
      feePayer: FeePayer;
    }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<SimpleStatusModel>('/advertising/trade/cancreate', {
        method: 'POST',
        body: {
          sellerId: authStore.user.Id,
          price: transfer.price,
          quantity: transfer.quantity,
          instrument: {
            id: transfer.instrumentId,
          },
          feePayer: transfer.feePayer,
          buyerGalleryUniqueCode: transfer.identifier,
        },
      });
    },
    async createTrade(transfer: {
      price: number;
      quantity: number;
      instrumentId: string;
      identifier: string;
      feePayer: FeePayer;
      verificationCode: string;
    }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<SimpleStatusModel>(
        `/advertising/trade/create${stringify({ code: transfer.verificationCode }, { addQueryPrefix: true })}`,
        {
          method: 'POST',
          body: {
            sellerId: authStore.user.Id,
            price: transfer.price,
            quantity: transfer.quantity,
            instrument: {
              id: transfer.instrumentId,
            },
            feePayer: transfer.feePayer,
            buyerGalleryUniqueCode: transfer.identifier,
          },
        },
      );
    },
    async getTrades({ force = false }: { force?: boolean }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      if (this.trades.length > 0 && !force) {
        return;
      }

      this.loadingTrades = true;

      const trades = await fetch<Trade[]>(
        `/advertising/${authStore.user.Id}/trade${stringify({ language }, { addQueryPrefix: true })}`,
      );

      this.trades = trades.map((trade) => {
        trade.SellerFullName = (trade.SellerFullName ?? '').replace(',', '');

        return trade;
      });
      this.loadingTrades = false;
    },
    async cancelTrade({ id }: { id: string }) {
      const fetch = useApiFetch();

      const response = await fetch<SimpleStatusModel>(
        `/advertising/trade/rejectbyseller${stringify({ orderId: id }, { addQueryPrefix: true })}`,
        { method: 'POST' },
      );

      if (response.Status) {
        const trade = this.trades.find((trade) => trade.Id === id);
        if (trade) {
          trade.State = TradeState.RejectedBySeller;
        }
      }

      return response;
    },
    async rejectTrade({ id }: { id: string }) {
      const fetch = useApiFetch();

      const response = await fetch<SimpleStatusModel>(
        `/advertising/trade/rejectbybuyer${stringify({ orderId: id }, { addQueryPrefix: true })}`,
        { method: 'POST' },
      );

      if (response.Status) {
        this.trades.splice(
          this.trades.findIndex((trade) => trade.Id === id),
          1,
        );
      }

      return response;
    },
    async signTrade({ id, verificationCode }: { id: string; verificationCode: string }) {
      const fetch = useApiFetch();

      const response = await fetch<SimpleStatusModel>(
        `/advertising/trade/sign${stringify({ orderId: id, code: verificationCode }, { addQueryPrefix: true })}`,
        { method: 'POST' },
      );

      if (response.Status) {
        this.trades.splice(
          this.trades.findIndex((trade) => trade.Id === id),
          1,
        );
      }

      return response;
    },
    _resetCache() {
      this.loadingTradeOffers = true;
      this.tradeOffersCache = {
        cs: { loaded: false, data: [], totalCount: 0 },
        en: { loaded: false, data: [], totalCount: 0 },
      };
      this.loadingTrades = true;
      this.trades = [];
      this.loadingMyTradeOffers = true;
      this.myTradeOffersCache = {
        cs: { loaded: false, data: [], totalCount: 0 },
        en: { loaded: false, data: [], totalCount: 0 },
      };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdvertisingStore, import.meta.hot));
}
