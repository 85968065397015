export enum ClientDocumentType {
  All = 0,
  Unknown = 1,
  AgreementAssetManagement = 2,
  Identity = 3,
  BankAccount = 4,
  Questionnaire = 5,
  W8Ben = 6,
  Statement = 7,
  StatementOfFinalOwnership = 8,
  YearlyStatement = 9,
  TaxStatement = 10,
  Agreement = 11,
  AgreementAmendmentBrokerage = 12,
  UpdatedAgreementAmendmentBrokerage = 13,
  ConfirmationStatement = 14,
  Voucher = 15,
  AgreementWithPermissionToAccessAccount = 16,
  QuarterlyStatement = 17,
  SourceOfIncomeStatement = 18,
  PortfolioStructure = 19,
  AgreementWithdrawal = 20,
  RepresentationLetter = 21,
  IndirectIdentification = 22,
  DIPStatement = 23,
  DIPYearlyStatement = 24,
  DIPAgreement = 25,
  DIPEmployerPaymentInfo = 26,
  DIPAgreementWithdrawal = 27,
  CorporateAccountOverview = 28,
  PepSourceOfIncomeStatement = 29,
}
