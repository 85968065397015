import { normalize } from '~/lib/string-formats';

declare namespace Gtag {
  interface Gtag {
    (command: 'event', eventName: string, eventParams?: { [key: string]: any }): void;
  }
}
type TrackingData = {
  email?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  phone?: boolean;
  city?: boolean;
  postalCode?: boolean;
  customContent?: Record<string, any>;
};

const getCookies = () => document.cookie.split('; ');
const tryGetCookie = (name: string) =>
  getCookies()
    .find((cookie) => cookie.includes(name))
    ?.split('=')[1];
const gtag: Gtag.Gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

export default defineNuxtPlugin(() => {
  const track = (event: string, data: TrackingData) => {
    const authState = useAuthStore();
    const fbcCookie = tryGetCookie('fbc');
    const fbpCookie = tryGetCookie('fbp');

    try {
      fbq('track', event, {
        ...(fbcCookie ? { fbc: fbcCookie } : {}),
        ...(fbpCookie ? { fbp: fbpCookie } : {}),
        ...(data.email ? { em: authState.user.Email.toLowerCase() } : {}),
        ...(data.firstName && authState.user.FirstName
          ? {
              fn: normalize(authState.user.FirstName.toLowerCase()),
            }
          : {}),
        ...(data.lastName && authState.user.LastName
          ? {
              ln: normalize(authState.user.LastName.toLowerCase()),
            }
          : {}),
        ...(data.phone && authState.user.Phone
          ? {
              ph: authState.user.Phone.replace(/[\s+]/g, ''),
            }
          : {}),
        ...(data.city
          ? {
              ct: normalize(
                authState.user.ResidencyAddress.City.replace(/[\s]/g, '').toLowerCase(),
              ),
            }
          : {}),
        ...(data.postalCode
          ? {
              zp: authState.user.ResidencyAddress.ZipCode.replace(/[\s]/g, '').slice(0, 5),
            }
          : {}),
        ...(data.customContent ? { contents: JSON.stringify(data.customContent) } : {}),
      });
    } catch (error) {}

    try {
      gtag('event', event, {
        ...(data.email ? { email: authState.user.Email } : {}),
        ...(data.firstName && authState.user.FirstName
          ? { firstName: authState.user.FirstName }
          : {}),
        ...(data.lastName && authState.user.LastName ? { lastName: authState.user.LastName } : {}),
        ...(data.phone && authState.user.Phone
          ? {
              phone: authState.user.Phone.replace(/[\s+]/g, ''),
            }
          : {}),
        ...(data.city
          ? {
              city: authState.user.ResidencyAddress.City,
            }
          : {}),
        ...(data.postalCode
          ? {
              postalCode: authState.user.ResidencyAddress.ZipCode,
            }
          : {}),
        ...(data.customContent ? { contents: data.customContent } : {}),
      });
    } catch (error) {}
  };

  return {
    provide: {
      track,
    },
  };
});
