import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

const AUTHENTICATED_ROUTES = ['account', 'dashboard'] as const;

export default defineNuxtPlugin(() => {
  // hotfix for nuxt generate running this code
  if (import.meta.server) {
    return;
  }

  const {
    // using $localePath instead of useLocalePath will not result in warning:
    // Calling useRoute within middleware may lead to misleading results
    $localePath: localePath,
  } = useNuxtApp();

  const router = useRouter();
  const authStore = useAuthStore();
  const securityStore = useSecurityStore();
  const layoutStore = useLayoutStore();
  const { getUnixTime, intervalToDuration, formatDuration } = useDateFns();

  let logoutTimeout: number | null = null;
  let countdown: number | null = null;
  let hasLoaded = false;

  const listenToEvents = () => {
    document.addEventListener('click', extendActivityLater);
    document.addEventListener('mousemove', extendActivityLater);
    window.addEventListener('scroll', extendActivityLater);
    // keydown instead of keypress to register arrow keys etc.
    document.addEventListener('keydown', extendActivityLater);
  };
  const redirectAfterLogout = () => {
    const currentRoute = router.currentRoute.value.name as string | undefined | null;
    const shouldRedirectToLogin = AUTHENTICATED_ROUTES.some((route) =>
      currentRoute?.includes(route),
    );

    if (shouldRedirectToLogin) {
      layoutStore.setAfterLoginPage({ page: router.currentRoute.value });

      router.push(localePath('login'));

      return;
    }

    router.push(localePath('/'));
  };
  const setAutomaticLogoutTimeout = (when: number) => {
    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout);
      logoutTimeout = null;
    }

    if (countdown) {
      window.clearInterval(countdown);
      countdown = null;
    }

    logoutTimeout = window.setTimeout(async () => {
      if (logoutTimeout) {
        window.clearTimeout(logoutTimeout);
      }

      layoutStore.lastLoggedUser = authStore.user.Email;

      await authStore.logout();

      redirectAfterLogout();
    }, when);

    const start = Date.now();
    let countdownWhen = Date.now() - when;
    countdown = window.setInterval(() => {
      const toolbox = document.getElementById('dev-toolbox');
      if (!toolbox) {
        window.clearInterval(countdown ?? 0);
        countdown = null;

        return;
      }

      countdownWhen += 1_000;

      toolbox.querySelector<HTMLSpanElement>('.logout span')!.innerText = formatDuration(
        intervalToDuration({
          start,
          end: new Date(countdownWhen),
        }),
      );
    }, 1_000);
  };
  const prepareAutomaticLogout = (expiresAt: number) => {
    // unix to milliseconds minus now milliseconds minus 10 seconds
    const expiresInMiliseconds = expiresAt * 1_000 - Date.now() - 10_000;

    setAutomaticLogoutTimeout(expiresInMiliseconds);
  };
  const extendActivity = async () => {
    const session = JSON.parse(localStorage.getItem(SESSION) || 'null') as Pick<
      typeof authStore,
      'user' | 'token'
    > | null;
    if (!session) {
      await authStore.logout();

      return;
    }

    let token = session.token;
    const hasExpired = getUnixTime(Date.now()) + 100 >= token.ExpiresAt;

    if (!session.token || hasExpired) {
      await authStore.logout();

      redirectAfterLogout();

      return;
    }

    if (!hasLoaded) {
      prepareAutomaticLogout(token.ExpiresAt);

      hasLoaded = true;

      return;
    }

    token = await securityStore.refreshToken({ refreshToken: token.RefreshToken });

    authStore._setToken({ token });
    authStore.persist();

    token = authStore.token;

    prepareAutomaticLogout(token.ExpiresAt);
  };
  const extendActivityLater = throttle(debounce(extendActivity, 1_100), 1_000, {
    leading: true,
    trailing: true,
  });

  listenToEvents();
  extendActivity();
});
