import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { NotificationType, type Notification } from '~/models/notifications';
import type { SimpleStatusModel } from '~/models/arbitrary';

export const NOTIFICATIONS = 'notifications';
export const KNOWN_PERMANENT_NOTIFICATIONS = [
  'IN_PROGRESS',
  'EMAIL_NOT_VERIFIED',
  'CRN_TAX_RES_CONFIRMATION_REQUIRED',
  'CRN',
  'CRN_IDENTIFICATION',
  'CRN_INFO_AND_DOCUMENTS',
  'CRN_INFO_ONLY',
  'VOTE',
  'IS_LOCKED',
  'ALTERNATIVE_USERIDENTIFICATION_REQUIRED',
  'MISSING_SOURCE_OF_INCOME_STATEMENT',
  'TBV_CT_UNKNOWN_BANK_ACCOUNT',
  'TBV_CT',
  // 'QUESTIONNAIRE_EXPIRED',
  'PERSONAL_DATA_CONFIRMATION',
  'FINISH_PERSONAL_DATA_CONFIRMATION_SOI',
  'FINISH_PERSONAL_DATA_CONFIRMATION_PEP',
  'ACCOUNT_IS_NOT_ACTIVE',
] as const;
export const KNOWN_INFO_NOTIFICATIONS = [
  'BANKID_AVAILABLE',
  'TAX_REPORT_AVAILABLE',
  'CLIENT_COMPLETED',
  // 'QUESTIONNAIRE_AGAIN_ENABLED',
  // 'QUESTIONNAIRE_AGAIN_CONTINUE',
  'SURVEY_RESULTS',
  'IDENTIFICATION_EXPIRED',
  'PRIMARY_IDENTIFICATION_EXPIRED',
  'DOCUMENT_VERIFICATION_IN_PROGRESS',
  'GALLERY_ELEMENT', // 2023 report
] as const;

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    loadedNotifications: false,
    infoNotifications: [] as Notification[],
    permanentNotifications: [] as Notification[],
    reshowNotifications: false,
    closedInfoNotifications: [] as string[],
    pauseNotifications: false,
  }),
  getters: {
    showInfoNotifications: (state) => {
      return state.infoNotifications.length > 0;
    },
    infoNotificationsCount: (state) => {
      return state.infoNotifications.length;
    },
  },
  actions: {
    async getNotifications({ force = false }: { force?: boolean }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (this.loadedNotifications && !force) {
        return;
      }

      const response = await fetch<Notification[]>(
        `/clients/${authStore.user.Id}/${authStore.user.Id}/notifications`,
      );

      this.setInfoNotifications(
        response.filter(
          (notification) =>
            notification.Type === NotificationType.Temporary &&
            KNOWN_INFO_NOTIFICATIONS.includes(notification.Code),
        ),
      );
      this.permanentNotifications = response
        .filter(
          (notification) =>
            notification.Type === NotificationType.Permanent &&
            KNOWN_PERMANENT_NOTIFICATIONS.includes(notification.Code),
        )
        .sort((a, b) => a.Priority - b.Priority);
      this.loadedNotifications = true;
    },
    setInfoNotifications(notifications: Notification[]) {
      this.closedInfoNotifications.forEach((closedNotification) => {
        const notificationsToRemove = notifications.filter(
          (notification) => notification.Code === closedNotification,
        );

        if (notificationsToRemove.length === 0) {
          return;
        }

        notificationsToRemove.forEach((notification) => {
          notifications.splice(notifications.indexOf(notification), 1);
        });
      });

      this.infoNotifications = [...notifications].sort((a, b) => a.Priority - b.Priority);
    },
    setReshowNotifications({ doShow }: { doShow: boolean }) {
      this.reshowNotifications = doShow;

      this._persist();
    },
    async setClosedInfoNotification({ notification }: { notification: Notification }) {
      const fetch = useApiFetch();
      const auth = useAuthStore();

      this.closedInfoNotifications = [...this.closedInfoNotifications, notification.Code];

      this._persist();

      if (!notification.MaxReshowCount) {
        return;
      }

      await fetch<SimpleStatusModel>(`clients/${auth.user.Id}/notifications/close`, {
        method: 'POST',
        body: notification,
      });
    },
    closeInfoNotification({ index }: { index: number }) {
      this.infoNotifications.splice(index, 1);
    },
    _persist() {
      if (this.closedInfoNotifications) {
        localStorage.setItem(
          NOTIFICATIONS,
          JSON.stringify({
            reshowNotifications: this.reshowNotifications,
            closedInfoNotifications: this.closedInfoNotifications,
          }),
        );
      }
    },
    _hydrate() {
      const notifications = JSON.parse(localStorage.getItem(NOTIFICATIONS) || '{}');

      this.closedInfoNotifications = notifications.closedInfoNotifications ?? [];
      this.setReshowNotifications(notifications.reshowNotifications ?? false);
    },
    _resetCache() {
      localStorage.removeItem(NOTIFICATIONS);

      this.loadedNotifications = false;
      this.infoNotifications = [];
      this.permanentNotifications = [];
      this.reshowNotifications = false;
      this.closedInfoNotifications = [];
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot));
}
