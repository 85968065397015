import { czechParser } from './czech';
import { polishParser } from './polish';
import { Parser } from './types';

const _parsers: {
  [key in 'CZ' | 'PL' | 'SK']: Parser | undefined;
} = {
  CZ: undefined,
  PL: undefined,
  SK: undefined,
};

export const registerValidator = (nationality: 'CZ' | 'PL' | 'SK', validator: Parser) => {
  _parsers[nationality] = validator;
};

export const registerImplementations = () => {
  _parsers.CZ = czechParser;
  _parsers.SK = czechParser;
  _parsers.PL = polishParser;
};

export const getParser = (nationality: 'CZ' | 'PL' | 'SK'): Parser => {
  const parser = _parsers[nationality];
  if (!parser) {
    throw `Provider for nationality '${nationality}' not implemented.`;
  }

  return parser;
};
