import { ParsingResult } from './types';
import { getPeselSum } from '../helpers/polish';
import { Gender } from '~/models/clients';

const checkDate = (year: number, month: number, day: number) => {
  const date = new Date(year, month - 1, day);
  return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
};

export const polishParser = (pesel: string): ParsingResult => {
  const { controlArray } = getPeselSum(pesel);

  let year = 1900 + controlArray[0] * 10 + controlArray[1];
  if (controlArray[2] >= 2 && controlArray[2] < 8) {
    year += Math.floor(controlArray[2] / 2) * 100;
  }
  if (controlArray[2] >= 8) {
    year -= 100;
  }

  const month = (controlArray[2] % 2) * 10 + controlArray[3];
  const day = controlArray[4] * 10 + controlArray[5];

  if (!checkDate(year, month, day)) {
    // dirty fix for development only - parse function should only run after
    // a validate function so that this can never happen
    return {
      birthDate: null as unknown as Date,
      gender: null as unknown as Gender,
    };
  }

  const gender = controlArray[9] % 2 === 1 ? Gender.Male : Gender.Female;

  return {
    birthDate: new Date(year, month - 1, day, 12, 0, 0, 0),
    gender,
  };
};
