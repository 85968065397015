import { defineStore } from 'pinia';
import { stringify } from 'qs';
import type { SimpleStatusModel, SimpleStringArrayModel, Voucher } from '~/models/arbitrary';

export const useVouchersStore = defineStore('vouchers', {
  state: () => ({
    checkingVoucher: false,
    redeemingVoucher: false,
    templates: [] as string[],
    loadingVouchers: true,
    vouchersCache: {
      loaded: false,
      data: [] as Voucher[],
    },
  }),
  getters: {
    vouchers: (state) => {
      if (state.vouchersCache.loaded) {
        return state.vouchersCache.data;
      }

      return [] as Voucher[];
    },
  },
  actions: {
    async checkValidity({ code }: { code: string }) {
      const fetch = useApiFetch();

      return await fetch<Voucher>(`/voucher/${code}`);
    },
    async apply({ code }: { code: string }) {
      const fetch = useApiFetch();
      const errorsStore = useErrorsStore();
      const portfoliosStore = usePortfoliosStore();
      const { wallet } = storeToRefs(portfoliosStore);

      const result = await fetch<SimpleStatusModel>(`/voucher/apply/${code}/${wallet.value.Id}`, {
        method: 'POST',
      });

      if (!result.Status) {
        errorsStore.showErrors({ errors: ['server-validations.GENERAL'] });
      }

      return result;
    },
    async getTemplates() {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      const response = await fetch<SimpleStringArrayModel>(
        `/voucher/templates${stringify({ locale: language }, { addQueryPrefix: true })}`,
      );

      this.templates = response.Values;
    },
    async getVouchers() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();
      const documentsStore = useDocumentsStore();
      const { compareDesc } = useDateFns();

      if (this.vouchersCache.loaded) {
        return;
      }

      this.loadingVouchers = true;

      const response = await fetch<Voucher[]>(`/voucher/client/${authStore.user.Id}`);
      const vouchersWithFileUrls = await Promise.all(
        response.map(async (voucher) => {
          if (
            voucher.DocumentId &&
            !(
              voucher.SenderCashTransactionId === null ||
              voucher.SenderCashTransactionId === undefined
            ) &&
            voucher.SenderCashTransactionId.length > 0
          ) {
            voucher.FileUrl = (
              await documentsStore.getClientDocumentDownloadLink({
                documentId: voucher.DocumentId!,
              })
            ).Link;
          }

          return voucher;
        }),
      );

      this.vouchersCache = {
        loaded: true,
        data: vouchersWithFileUrls.toSorted((a, b) =>
          compareDesc(new Date(a.CreatedOn!), new Date(b.CreatedOn!)),
        ),
      };
      this.loadingVouchers = false;
    },
    async order({ amount, template }: { amount: number; template: string }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      const response = await fetch<Voucher>('/voucher/client/create', {
        method: 'POST',
        body: {
          amount,
          template,
          currency: authStore.user.BaseDefaultCurrency,
          language,
        },
      });

      if (!this.vouchersCache.loaded) {
        this.vouchersCache.loaded = true;
        this.loadingVouchers = false;
      }
      this.vouchersCache.data.unshift(response);
    },
    async cancel({ id }: { id: string }) {
      const fetch = useApiFetch();

      const response = await fetch<SimpleStatusModel>(`/voucher/delete/${id}`, { method: 'POST' });

      if (response.Status) {
        this.vouchersCache.data.splice(
          this.vouchersCache.data.findIndex((voucher) => voucher.Id === id),
          1,
        );
      }

      return response;
    },
    _resetCache() {
      this.checkingVoucher = false;
      this.redeemingVoucher = false;
      this.loadingVouchers = true;
      this.vouchersCache = {
        loaded: false,
        data: [],
      };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVouchersStore, import.meta.hot));
}
