export function normalize(input: string) {
  if (!input) {
    return input;
  }

  return input
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
}
