import { defineStore } from 'pinia';

export const useErrorsStore = defineStore('errors', {
  state: () => ({
    hasErrors: false,
    errors: [] as string[],
  }),
  actions: {
    showErrors({ errors, autohideAfter }: { errors: string[]; autohideAfter?: number }) {
      const sanitizedErrors = errors.map((error) => error.split('|')[0]);

      this.errors = sanitizedErrors;
      this.hasErrors = sanitizedErrors.length > 0;

      if (autohideAfter) {
        window.setTimeout(() => {
          this.removeErrors();
        }, autohideAfter);
      }
    },
    removeErrors() {
      this.errors = [];
      this.hasErrors = false;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useErrorsStore, import.meta.hot));
}
