<script lang="ts">
import type { NuxtError } from '#app';

type Props = {
  error: NuxtError;
};
</script>

<script setup lang="ts">
defineProps<Props>();

const {
  public: { cdnUrl },
} = useRuntimeConfig();
const { t } = useI18n();
const localePath = useLocalePath();

useCustomSeoMeta({
  title: t('not-found.meta.title'),
  description: t('not-found.meta.content'),
});

const randomImage = Math.floor(Math.random() * (2 - 1 + 1) + 1);
</script>

<template>
  <NuxtLayout name="light">
    <main>
      <section class="background--light introduction">
        <div class="container">
          <div class="row">
            <div class="col justify-center">
              <h1 v-html="$t('not-found.introduction.title')" />

              <p>{{ $t('not-found.introduction.caption') }}</p>

              <Button as="NuxtLink" theme="light" :to="localePath('index')">
                {{ $t('not-found.introduction.actions.to-homepage') }}
              </Button>
            </div>

            <div class="col justify-center">
              <picture>
                <img
                  alt=""
                  aria-hidden="true"
                  class="cover-image"
                  width="540"
                  height="480"
                  :src="`${cdnUrl}/images/redesign/not-found/cover_${randomImage}.png`"
                  :srcset="`${cdnUrl}/images/redesign/not-found/cover_${randomImage}@2x.png 2x, ${cdnUrl}/images/redesign/not-found/cover_${randomImage}@3x.png 3x`"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
    </main>
  </NuxtLayout>
</template>
