const getMultiplier = (index: number): number => {
  switch (index % 4) {
    case 1:
      return 1;
    case 2:
      return 3;
    case 3:
      return 7;
    case 0:
      return 9;
  }

  throw 'Something went wrong with the index calculation.';
};

export const getPeselSum = (pesel: string) => {
  const arr = pesel.split('').map((char) => parseInt(char, 10));
  let sum = 0;

  for (let i = 0; i < arr.length - 1; i++) {
    sum += arr[i] * getMultiplier(i + 1);
  }

  return { sum, controlArray: arr };
};
