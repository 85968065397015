import { ParsingResult } from './types';
import { getGender, getRealMonth, getYearWithHundreds } from '../helpers/czech';

export const czechParser = (identityNumber: string): ParsingResult => {
  const year = parseInt(identityNumber.substring(0, 2), 10);
  const month = parseInt(identityNumber.substring(2, 4), 10);
  const day = parseInt(identityNumber.substring(4, 6), 10);

  const yearWithHundreds = getYearWithHundreds(identityNumber, year);
  const realMonth = getRealMonth(year, month) - 1;

  return {
    birthDate: new Date(yearWithHundreds, realMonth, day, 12, 0, 0, 0),
    gender: getGender(yearWithHundreds, month),
  };
};
