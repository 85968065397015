export enum OperationType {
  ResetPassword = 1,
  AgreementSigning = 2,
  ChangeEmail = 4,
  VerifyPhone = 5,
  Other = 6,
  TwoFALogin = 7,
  CodeLogin = 8,
  PhoneCodeLogin = 9,
  DocumentDownload = 10,
  Verification = 11,
  MoneyTransfer = 12,
  VerifyEmail = 13,
  CreateTrade = 14,
  SignTrade = 15,
  RecurringOrder = 16,
  AgreementWithdrawal = 17,
  WultraRegistration = 18,
  MoneyWithdrawal = 19,
  Login = 20,
  DIPAgreementSigning = 21,
  PensionTermsAgreement = 22,
  TaxTreatmentTransfer = 23,
  DIPAgreementWithdrawal = 24,
  PensionTermsCancellation = 25,
  PortfolioLiquidation = 26,
  SellOrder = 27,
  CreatePassword = 28,
}
