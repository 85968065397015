import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useApiFetch } from '~/composables/useApiFetch';
import { InstrumentState } from '~/models/instruments';
import { type Instrument, type InstrumentGroup } from '~/models/instruments';

type InstrumentsCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: Instrument[] };
};
type InstrumentGroupsCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: InstrumentGroup[] };
};

export const useInstrumentsStore = defineStore('instruments', {
  state: () => ({
    loadingInstruments: true,
    loadingInstrument: true,
    instrumentsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as InstrumentsCache,
    loadingInstrumentGroups: true,
    instrumentGroupsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as InstrumentGroupsCache,
  }),
  getters: {
    instruments: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.instrumentsCache[language].loaded) {
        return state.instrumentsCache[language].data;
      }

      return [];
    },
    instrumentGroups: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.instrumentGroupsCache[language].loaded) {
        return state.instrumentGroupsCache[language].data;
      }

      return [];
    },
  },
  actions: {
    async getInstruments({ force = false }: { force?: boolean }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (this.instrumentsCache[language].loaded && !force) {
        return this.instrumentsCache[language].data;
      }

      this.loadingInstruments = true;

      // SET_INSTRUMENT_CACHE is also mutating cache (not clearing cache would result in duplicities)
      if (this.instrumentsCache[language].data.length > 0) {
        this.instrumentsCache[language] = {
          data: [],
          loaded: false,
        };
      }

      const instruments = await fetch<Instrument[]>(
        `/product/instruments${stringify(
          { language: locale.value, includeMarketPrices: true },
          { addQueryPrefix: true, skipNulls: true },
        )}`,
      );

      const beingSubscribedOrEvaluatingResults = instruments
        .filter(
          (instrument) =>
            instrument.State === InstrumentState.BeingSubscribed ||
            instrument.State === InstrumentState.EvaluatingResults,
        )
        .sort((a, b) => a.SortOrder - b.SortOrder);
      const inPreparation = instruments
        .filter((instrument) => instrument.State === InstrumentState.InPreparation)
        .sort((a, b) => b.SortOrder - a.SortOrder);
      const hidden = instruments
        .filter((instrument) => instrument.State === InstrumentState.Hidden)
        .sort((a, b) => a.SortOrder - b.SortOrder);
      const subscribed = instruments
        .filter((instrument) => instrument.State === InstrumentState.Subscribed)
        .sort((a, b) => a.SortOrder - b.SortOrder);
      const sold = instruments
        .filter((instrument) => instrument.State === InstrumentState.Sold)
        .sort((a, b) => a.SortOrder - b.SortOrder);
      const partlySold = instruments
        .filter((instrument) => instrument.State === InstrumentState.PartlySold)
        .sort((a, b) => a.SortOrder - b.SortOrder);
      const subscriptionUnsuccessful = instruments
        .filter((instrument) => instrument.State === InstrumentState.SubscriptionUnsuccessful)
        .sort((a, b) => a.SortOrder - b.SortOrder);

      this.instrumentsCache[language] = {
        loaded: true,
        data: [
          ...beingSubscribedOrEvaluatingResults,
          ...inPreparation,
          ...hidden,
          ...subscribed,
          ...sold,
          ...partlySold,
          ...subscriptionUnsuccessful,
        ],
      };
      this.loadingInstruments = false;
    },
    setInstrumentState({ id, state }: { id: string; state: InstrumentState }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      this.instrumentsCache[language].data.find((instrument) => instrument.Id === id)!.State =
        state;
    },
    setInstrumentHideResult({ id }: { id: string }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      this.instrumentsCache[language].data.find((instrument) => instrument.Id === id)!.HideResults =
        false;
    },
    async getInstrumentGroups({ force = false }: { force?: boolean }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (this.instrumentGroupsCache[language].loaded && !force) {
        return this.instrumentGroupsCache[language].data;
      }

      this.loadingInstrumentGroups = true;

      const instrumentGroups = await fetch<InstrumentGroup[]>(
        `/staticdata/instrumentgroups${stringify(
          { language },
          { addQueryPrefix: true, skipNulls: true },
        )}`,
      );

      this.instrumentGroupsCache[language] = {
        loaded: true,
        data: instrumentGroups.sort((a, b) => a.Order - b.Order),
      };

      this.loadingInstrumentGroups = false;
    },
    async getInstrument({ slug, force }: { slug: string; force?: boolean }) {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (
        !force &&
        this.instrumentsCache[language].loaded &&
        this.instrumentsCache[language].data.find((instrument) => instrument.Slug === slug)
      ) {
        return this.instrumentsCache[language].data.find((instrument) => instrument.Slug === slug);
      }

      this.loadingInstrument = true;

      const instrument = await fetch<Instrument>(
        `/product/instrument${stringify(
          { language, slug },
          { addQueryPrefix: true, skipNulls: true },
        )}`,
      );

      if (!instrument) {
        return null;
      }

      const cachedInstrument = this.instrumentsCache[language].data.find(
        (cachedInstrument) => cachedInstrument.Id === instrument.Id,
      );
      if (!cachedInstrument) {
        this.instrumentsCache[language].data.push(instrument);
      } else if (this.instrumentsCache[language].loaded) {
        const index = this.instrumentsCache[language].data.findIndex(
          (instrument) => instrument.Id === cachedInstrument.Id,
        );
        this.instrumentsCache[language].data.splice(index, 1, instrument);
      }

      this.loadingInstrument = false;

      return instrument;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInstrumentsStore, import.meta.hot));
}
