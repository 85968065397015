import VueTippy, { Tippy } from 'vue-tippy';

type TippyProps = InstanceType<typeof Tippy>['$props'];

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    defaultProps: {
      allowHTML: true,
      appendTo: () => document.body,
      arrow: false,
      delay: 0,
      duration: [325, 275],
      interactive: true,
      offset: [0, 8],
      trigger: 'mouseenter focus',
      zIndex: 10001,
    } as TippyProps,
  });
});
