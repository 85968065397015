import { ValidationResult } from './types';
import { getPeselSum } from '../helpers/polish';

export const polishValidator = (pesel: string): ValidationResult => {
  if (!/^\d+$/.test(pesel)) {
    return { isValid: false, errorReason: 'common.registration.error.wrong-number' };
  }

  if (pesel.length !== 11) {
    return { isValid: false, errorReason: 'common.registration.error.short-number' };
  }

  const { sum } = getPeselSum(pesel);

  const modulo = sum % 10;
  const lastDigit = parseInt(pesel.substring(10), 10);

  if (!(10 - (modulo === 0 ? 10 : modulo) === lastDigit)) {
    return { isValid: false, errorReason: 'common.registration.error.identity-number' };
  }

  return { isValid: true };
};
