import { stringify } from 'qs';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { useApiFetch } from '~/composables/useApiFetch';
import {
  type OccupationStatusModel,
  type CallRequest,
  type SimpleStatusModel,
  type SimpleStringModel,
  OperationType,
} from '~/models/arbitrary';
import { type BankAccount, type Client } from '~/models/clients';

type BankAccountsCache = { loaded: boolean; data: BankAccount[] };

export const useClientStore = defineStore('clients', {
  state: () => ({
    bankAccountsCache: { loaded: false, data: [] } as BankAccountsCache,
    loadingBankAccounts: true,
  }),
  getters: {
    bankAccounts: (state) => {
      if (state.bankAccountsCache.loaded) {
        return state.bankAccountsCache.data;
      }

      return [];
    },
  },
  actions: {
    requestCall(payload: CallRequest) {
      const fetch = useApiFetch();

      return fetch<SimpleStatusModel>('/clients/requestcall', {
        method: 'POST',
        body: payload,
      });
    },
    async getByEmail({ email }: { email: string }) {
      const fetch = useApiFetch();

      const { Id } = await fetch<{ Id: string }>(`/clients/find/${email}`);

      return await this.getById({ id: Id });
    },
    async getById({ id }: { id: string }) {
      const fetch = useApiFetch();

      const client = await fetch<Client>(`/clients/${id}`);

      return {
        ...client,
        ...(typeof client.BaseDefaultCurrency === 'undefined' || client.BaseDefaultCurrency === ''
          ? { BaseDefaultCurrency: 'CZK' }
          : {}),
      };
    },
    async update(client: Partial<Client>) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      const response = await fetch<Client>(`/clients/${authStore.user.Id}`, {
        method: 'PUT',
        body: {
          ...client,
          ...(typeof client.BaseDefaultCurrency === 'undefined' || client.BaseDefaultCurrency === ''
            ? { BaseDefaultCurrency: 'CZK' }
            : {}),
        },
      });

      authStore.user = response;

      return response;
    },
    async updatePhoneNumber({
      newPhoneNumber,
      verificationCode,
    }: {
      newPhoneNumber: string;
      verificationCode: string;
    }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      await fetch(
        `/clients/${authStore.user.Id}/phonenumber`,

        {
          method: 'PUT',
          body: {
            phoneNumber: newPhoneNumber,
            phoneNumberConfirmation: newPhoneNumber,
            code: verificationCode,
          },
        },
      );
    },
    async confirmPhoneChange({
      newPhoneNumber,
      verificationCode,
    }: {
      newPhoneNumber: string;
      verificationCode: string;
    }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<Client>(
        `/clients/${authStore.user.Id}/phonenumber/confirm/${verificationCode}`,
        {
          method: 'PUT',
          body: {
            phoneNumber: newPhoneNumber,
            phoneNumberConfirmation: newPhoneNumber,
          },
        },
      );
    },
    async updateEmail({ email, verificationCode }: { email: string; verificationCode: string }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<Client>(`/clients/email`, {
        method: 'PUT',
        body: {
          email,
          emailConfirmation: email,
          code: verificationCode,
          targetClientId: authStore.user.Id,
        },
      });
    },
    async requestMobileLink({ redirectUri }: { redirectUri: string }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<{ Id: string }>(
        `/sms/mobileupload/${authStore.user.Id}${stringify(
          { page: redirectUri, targetClientId: authStore.user.Id },
          { addQueryPrefix: true },
        )}`,
        {
          method: 'POST',
        },
      );
    },
    async getBankAccounts({ force }: { force?: boolean }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      if (!force && this.bankAccountsCache.loaded && this.bankAccountsCache.data.length > 0) {
        return;
      }

      this.loadingBankAccounts = true;

      const bankAccounts = await fetch<BankAccount[]>(`/clients/${authStore.user.Id}/bankaccounts`);

      this.bankAccountsCache.loaded = true;
      this.bankAccountsCache.data = bankAccounts;
      this.loadingBankAccounts = false;
    },
    async removeBankAccount({ id }: { id: string }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      try {
        await fetch<BankAccount>(`/clients/${authStore.user.Id}/bankaccount/${id}`, {
          method: 'DELETE',
        });

        this.bankAccountsCache.data.splice(
          this.bankAccountsCache.data.findIndex((bankAccount) => bankAccount.Id === id),
          1,
        );

        return true;
      } catch (error) {
        return null;
      }
    },
    async addOrUpdateBankAccount({ bankAccount }: { bankAccount: Partial<BankAccount> }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      const response = await fetch<BankAccount | null>(
        `/clients/${authStore.user.Id}/bankaccount`,
        {
          method: 'POST',
          body: bankAccount,
        },
      );

      if (response) {
        const bankAccountIndex = this.bankAccountsCache.data.findIndex(
          (bankAccount) => bankAccount.Id === response.Id,
        );
        if (bankAccountIndex !== -1) {
          this.bankAccountsCache.data.splice(bankAccountIndex, 1, response);
        } else {
          this.bankAccountsCache.data.push(response);
        }
      }

      return response;
    },
    async checkIdentityNumber({ identityNumber }: { identityNumber: string }) {
      const fetch = useApiFetch();

      return await fetch<OccupationStatusModel>(`/clients/identitynumber/${identityNumber}`);
    },
    async runOcr() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch<SimpleStatusModel>(`/clients/${authStore.user.Id}/runocr`, {
        method: 'POST',
      });
    },
    async signAgreement({ verificationCode }: { verificationCode: string }) {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return await fetch(`/verification/agreement/sign`, {
        method: 'POST',
        body: {
          targetClientId: authStore.user.Id,
          operationType: OperationType.AgreementSigning,
          code: verificationCode,
        },
      });
    },
    async acceptAmendment() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      await fetch<{ Id: string }>(`/clients/${authStore.user.Id}/amendmentaccepted`, {
        method: 'POST',
      });
    },
    async updateAmendmentConsent() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      await fetch<{ Id: string }>(`/clients/${authStore.user.Id}/updatedamendmentaccepted`, {
        method: 'POST',
      });
    },
    async getBankIdLoginUrl() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return (await fetch<SimpleStringModel>(`/bankid/${authStore.user.Id}/loginurl`)).Result;
    },
    async confirmPersonalData() {
      const fetch = useApiFetch();
      const authStore = useAuthStore();

      return (
        await fetch<SimpleStatusModel>(`/clients/${authStore.user.Id}/approvepersonaldata`, {
          method: 'POST',
        })
      ).Status;
    },
    _resetCache() {
      this.bankAccountsCache = { loaded: false, data: [] };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot));
}
