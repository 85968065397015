const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';

  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  outer.parentNode!.removeChild(outer);

  document.documentElement.setAttribute(
    'style',
    `--scrollbar-width: ${
      document.documentElement.scrollHeight === document.documentElement.clientHeight
        ? 0
        : widthNoScroll - widthWithScroll
    }px`,
  );
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', async () => {
    await nextTick();

    getScrollbarWidth();
  });
});
