import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useAuthStore } from './auth';
import { type Question, type Questionnaire } from '~/models/questionnaires';

export const useQuestionnairesStore = defineStore('questionnaires', {
  state: () => ({
    creatingQuestionnaire: false,
    loadingQuestionnaires: true,
    questionnaires: [] as Questionnaire[],
    loadingQuestion: false,
    currentQuestion: null as Question | null,
    template: null as Questionnaire | null,
  }),
  getters: {
    latestQuestionnaire: (state) => {
      const { compareDesc } = useDateFns();

      const completedQuestionnaires = state.questionnaires
        .filter((questionnaire) => !!questionnaire.IsCompleted)
        .sort((a, b) => compareDesc(new Date(a.CreatedOn), new Date(b.CreatedOn)));

      return completedQuestionnaires.length > 0 ? completedQuestionnaires[0] : null;
    },
    latestIncompleteQuestionnaire: (state) => {
      const { compareDesc } = useDateFns();

      const completedQuestionnaires = state.questionnaires
        .filter((questionnaire) => !questionnaire.IsCompleted)
        .sort((a, b) => compareDesc(new Date(a.CreatedOn), new Date(b.CreatedOn)));

      return completedQuestionnaires.length > 0 ? completedQuestionnaires[0] : null;
    },
  },
  actions: {
    async getAllTemplates() {
      if (this.template) {
        return;
      }

      const fetch = useApiFetch();
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      this.template = await fetch<Questionnaire>(
        `/questionnaire${stringify({ language }, { addQueryPrefix: true })}`,
      );
    },
    async create() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      this.creatingQuestionnaire = true;

      await this.getAllTemplates();
      const response = await fetch<Questionnaire>(
        `/questionnaire/${authStore.user.Id}/create${stringify(
          { questionnaireTemplateId: this.template!.Id },
          { addQueryPrefix: true },
        )}`,
        {
          method: 'POST',
        },
      );

      this.questionnaires.push(response);
      this.creatingQuestionnaire = false;
    },
    async getAll() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      this.loadingQuestionnaires = true;
      this.questionnaires = await fetch<Questionnaire[]>(`/questionnaire/${authStore.user.Id}`);
      this.loadingQuestionnaires = false;
    },
    async getCurrentQuestion() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      const latestQuestionnaire = this.latestIncompleteQuestionnaire ?? this.latestQuestionnaire;

      this.loadingQuestion = true;
      this.currentQuestion = await fetch<Question>(
        `/questionnaire/${authStore.user.Id}/currentquestion${stringify(
          { questionnaireId: latestQuestionnaire!.Id },
          { addQueryPrefix: true },
        )}`,
      );

      if (!this.currentQuestion && latestQuestionnaire && latestQuestionnaire.Blocked) {
        const answeredQuestions = latestQuestionnaire.Questions.filter(
          (question) => question.Answered,
        );

        this.currentQuestion = {
          Id: answeredQuestions[answeredQuestions.length - 1].Id,
          Blocked: true,
        } as Question;
      }

      this.loadingQuestion = false;
    },
    async getPreviousQuestion() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (!this.currentQuestion) {
        return;
      }

      this.loadingQuestion = true;
      this.currentQuestion = await fetch<Question>(
        `/questionnaire/${authStore.user.Id}/previousquestion${stringify(
          {
            questionnaireId: this.latestIncompleteQuestionnaire!.Id,
            questionId: this.currentQuestion.Id,
          },
          { addQueryPrefix: true },
        )}`,
      );
      this.loadingQuestion = false;
    },
    async answerQuestion({
      selectedAnswers,
      value,
    }: {
      selectedAnswers: string[];
      value?: number;
    }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (!this.currentQuestion) {
        return;
      }

      this.loadingQuestion = true;
      this.currentQuestion = await fetch<Question | null>(
        `/questionnaire/${authStore.user.Id}/answerquestion${stringify(
          {
            questionnaireId: this.latestIncompleteQuestionnaire!.Id,
            questionId: this.currentQuestion.Id,
            selectedAnswers,
            value,
          },
          { skipNulls: true, addQueryPrefix: true },
        )}`,
        { method: 'POST' },
      );
      this.loadingQuestion = false;
    },
    async evaluate() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      await fetch<Questionnaire>(
        `/questionnaire/${authStore.user.Id}/${this.latestIncompleteQuestionnaire!.Id}/evaluate`,
        { method: 'POST' },
      );
    },
    _resetCache() {
      this.creatingQuestionnaire = false;
      this.loadingQuestionnaires = true;
      this.questionnaires = [];
      this.loadingQuestion = false;
      this.currentQuestion = null;
      this.template = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useQuestionnairesStore, import.meta.hot));
}
