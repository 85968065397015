import { type FetchOptions } from 'ofetch';

export function useApiFetch() {
  const localePath = useLocalePath();
  const router = useRouter();
  const config = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: config.public.apiUrl,
    headers: new Headers({
      Accept: 'application/json',
    }),
    onRequest(context) {
      const { $i18n } = useNuxtApp();
      const { token } = useAuthStore();

      const headers = new Headers(context.options.headers);
      headers.set(
        'Accept-Language',
        $i18n.locales.value.find((availableLocale) => availableLocale.code === $i18n.locale.value)!
          .language!,
      );

      if (Object.keys(token).length > 0) {
        headers.set('Authorization', `Bearer ${token.AccessToken}`);
      }

      /* get token - block start */
      if (
        typeof context.request === 'string' &&
        (context.request.includes('/token') || context.request.includes('/documents/amendment'))
      ) {
        context.options.baseURL = context.options.baseURL?.replace('/api/v1', '');
      }

      if (headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
        context.options.body = new URLSearchParams(
          context.options.body as unknown as Record<string, any>,
        );
      }
      /* get token - block end */

      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json');
      }

      // hack so that ofetch can set all the correct headers
      if (headers.get('Content-Type') === 'multipart/form-data') {
        headers.delete('Content-Type');
      }

      context.options.headers = headers;
    },
    onResponseError({ error, response }) {
      const authStore = useAuthStore();

      if (
        ((error && error.message === 'Network Error') || response.status === 400) &&
        authStore.isLoggedIn
      ) {
        authStore.logout();

        router.push(localePath('login'));

        return;
      }

      if (response && response.status) {
        if (response.status === 401 && authStore.isLoggedIn) {
          authStore.logoutRemote();

          return;
        }

        // if (response.status === 500) {
        //   return;
        // }
      }

      throw error ?? { ...response._data };
    },
  };

  return $fetch.create(fetchOptions);
}
