type Props = {
  title: string | Ref<string>;
  description?: string | Ref<string>;
  image?: string;
};

export function useCustomSeoMeta({ title, description, image }: Props) {
  const {
    public: { cdnUrl },
  } = useRuntimeConfig();
  const { locale } = useI18n();
  const i18nHead = useLocaleHead({ addSeoAttributes: true });

  const isTitleString = typeof title === 'string';
  const isDescriptionString = typeof description === 'string';

  const customImage = computed(
    () => image ?? `${cdnUrl}/images/redesign/meta/${locale.value}/main.png`,
  );

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
  });

  useSeoMeta({
    title: isTitleString ? title : () => title.value,
    twitterTitle: isTitleString ? title : () => title.value,
    ...(description
      ? {
          description: isDescriptionString ? description : () => description.value,
          ogDescription: isDescriptionString ? description : () => description.value,
          twitterDescription: isDescriptionString ? description : () => description.value,
        }
      : {}),
    ogImage: customImage.value,
    twitterImage: customImage.value,
  });
}
