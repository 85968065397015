export enum GeneralDocumentType {
  Unknown = 1,
  TermsAndConditions = 2,
  PriceList = 3,
  PortuBankAccount = 4,
  ComplaintsProcedure = 5,
  InformationOnInvestmentsServices = 6,
  InformationOnSecuritiesFund = 7,
  StrategyImplementationGuidelines = 8,
  CustomersCategorization = 9,
  MeasuresToAvoidConflicts = 10,
  PrivacyPolicy = 11,
  TermsOfUse = 12,
  AgreementAssetManagement = 13,
  StatementOfFinalOwnership = 14,
  W8Ben = 15,
  Agreement = 16,
  AgreementAmendmentBrokerage = 17,
  UpdatedAgreementAmendmentBrokerage = 18,
  BabyAccountTermsAndConditions = 19,
  BabyAccountAgreement = 20,
  PortfolioStructure = 21,
  AgreementWithPermissionToAccessAccount = 22,
  AssetsRelocationToPortu = 23,
  InviteFriendsConditions = 24,
  ETFFactSheets = 25,
  MoneyWithdrawalForm = 26,
  InvestmentMethodology = 27,
  DiscountProgramConditions = 28,
  SourceOfIncomeStatement = 29,
  TermOfUseMobileApp = 30,
  SecondaryTradeConditions = 31,
  AgreementWithdrawal = 32,
  RepresentationLetter = 33,
  InvestmentReserveDescription = 34,
  MarketingCHCI500Conditions = 35,
  EsgPrecontractInformation = 36,
  IndirectIdentification = 37,
  InvestmentCertificateAgreement = 38,
  GalleryProspect = 39,
  DIPAgreement = 40,
  DIPTermsAndConditions = 41,
  DIPWithdrawalConfirmation = 42,
  DIPEarlyWithdrawalInformation = 43,
}
