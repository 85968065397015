import { Gender } from '~/models/clients';

export const getRealMonth = (year: number, month: number) => {
  if (month > 70 && year > 2003) {
    month -= 70;
  } else if (month > 50) {
    month -= 50;
  } else if (month > 20 && year > 2003) {
    month -= 20;
  }

  return month;
};

export const getYearWithHundreds = (identityNumber: string, year: number) => {
  if (identityNumber.length === 9) {
    return year + 1900;
  }

  if (year > new Date().getUTCFullYear() % 100) {
    return year + 1900;
  }

  return year + 2000;
};

export const getGender = (year: number, month: number) => {
  if ((month > 70 && year > 2003) || month > 50) {
    return Gender.Female;
  } else if (month > 20 && year > 2003) {
    return Gender.Male;
  }

  return Gender.Male;
};
