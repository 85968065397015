export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#b20000"},{"name":"msapplication-TileColor","content":"#b20000"},{"name":"msapplication-config","content":"https://cdn.portu.cz/gallery/images/browserconfig.xml"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"https://cdn.portu.cz/gallery/images/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"https://cdn.portu.cz/gallery/images/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"https://cdn.portu.cz/gallery/images/favicon-16x16.png"},{"rel":"manifest","href":"https://cdn.portu.cz/gallery/images/site.webmanifest"},{"rel":"mask-icon","href":"https://cdn.portu.cz/gallery/images/safari-pinned-tab.svg","color":"#b20000"},{"rel":"shortcut icon","type":"image/x-icon","href":"https://cdn.portu.cz/gallery/images/favicon.ico"}],"style":[],"script":[{"src":"/smartsupp.js","tagPosition":"bodyClose","type":"text/javascript","async":true},{"type":"text/javascript","tagPosition":"bodyOpen","children":"window.addEventListener('DOMContentLoaded',()=>{window.onload=function(){\n            setTimeout(function(){(function(w,d,s,l,i){w[l]=w[l]||[];\n            w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});\n            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';\n            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;\n            f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WRZZPPL');},\n            5e3)}});"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"prefetch":true,"prefetchOn":{"visibility":false,"interaction":true},"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false