<script setup lang="ts">
import { v4 as uuid } from 'uuid';

type Props = {
  disabled?: boolean;
  loading?: boolean;
  success?: boolean;
  as?: 'nuxt-link' | 'NuxtLink' | 'a' | 'button';
  to?: string;
  theme?: 'dark' | 'light';
  variant?: 'text' | 'contained' | 'outlined';
};
export type ButtonProps = Props;
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  loading: false,
  success: false,
  as: 'button',
  to: undefined,
  theme: 'dark',
  variant: 'contained',
});
const slots = defineSlots<{
  default(props: {}): any;
  'leading-icon'(props: {}): any;
  'trailing-icon'(props: {}): any;
}>();
const emit = defineEmits<{ click: [event: MouseEvent] }>();
const { t } = useI18n();

const attributes = computed(() => ({
  ...((props.as === 'nuxt-link' || props.as === 'NuxtLink') && {
    to: props.to,
  }),
  ...(props.as === 'a' && {
    href: props.to,
    target: '_blank',
  }),
  ...(props.as === 'button' && { type: 'button', disabled: props.disabled || props.loading }),
}));
const svgTitle = computed(() => {
  if (props.loading && !props.success) {
    return t('global.sending');
  }

  if (props.success) {
    return t('global.successfully-sent');
  }

  return null;
});
const component = computed(() =>
  ['nuxt-link', 'NuxtLink', 'a'].includes(props.as) ? resolveComponent('NuxtLink') : 'button',
);
const canShowLeadingIcon = computed(() => !!slots['leading-icon']);
const canShowTrailingIcon = computed(() => !!slots['trailing-icon']);

const internalId = uuid();

function onClick(event: MouseEvent) {
  emit('click', event);
}
</script>

<template>
  <component
    v-bind="attributes"
    :is="component"
    class="button"
    :class="{
      [`button--${variant}`]: true,
      [`button--${theme}`]: true,
      'button--loading': loading,
      'button--success': success,
    }"
    @click="onClick"
  >
    <div v-if="canShowLeadingIcon" class="button__icon button__icon--leading">
      <slot name="leading-icon" />
    </div>

    <div v-if="loading" class="button__loader">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        :aria-labelledby="`${internalId}-icon-title`"
      >
        <title :id="`${internalId}-icon-title`">{{ svgTitle }}</title>
        <path
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
          :fill="!success ? 'currentColor' : 'transparent'"
        />
        <path
          d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
          class="spinner_5nOS"
          :fill="!success ? 'currentColor' : 'transparent'"
        />
        <polyline
          :stroke="success ? 'currentColor' : 'transparent'"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="20 6 9 17 4 12"
        />
      </svg>
    </div>

    <span v-if="loading" class="button__text-wrapper">
      <slot />
    </span>

    <slot v-else />

    <div v-if="canShowTrailingIcon" class="button__icon button__icon--trailing">
      <slot name="trailing-icon" />
    </div>
  </component>
</template>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0.65rem 1.75rem;

  border-radius: 0.375rem;
  border-width: 0.0625rem;
  border-style: solid;

  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;

  @media (prefers-reduced-motion: no-preference) {
    transition:
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: background-color, border-color, color, width;
  }

  &:is(a) {
    display: inline-flex;

    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.button--inline-text {
  padding: 0;

  width: auto;
}

.button--icon {
  display: inline-flex;
  align-items: center;

  padding: 0 0.25rem;
  margin: 0;

  border-width: 0;

  box-sizing: content-box;
}

.button__icon {
  display: flex;

  &--leading {
    margin-right: 0.5rem;
  }

  &--trailing {
    margin-left: 0.5rem;
  }
}

.button--link {
  margin: 0;
  padding: 0;

  text-decoration: underline;
}

.button--loading {
  position: relative;
}

a.button--text,
.button--text {
  --color: var(--red-basic);

  border-color: transparent;
  background-color: transparent;

  color: var(--color);

  &:visited {
    --color: var(--red-basic);
  }

  &:hover {
    --color: var(--white);
  }

  &[disabled] {
    --color: var(--brown-light);
  }

  &.button--light {
    &:visited {
      --color: var(--red-basic);
    }

    &:hover {
      --color: var(--black);
    }

    &[disabled] {
      --color: var(--gray-light);
    }
  }
}

a.button--contained,
.button--contained {
  --color: var(--white);

  border-color: var(--red-basic);
  background-color: var(--red-basic);
  color: var(--color);

  &:visited {
    --color: var(--white);
  }

  &:hover {
    background-color: var(--red-dark);
    border-color: var(--red-dark);
  }

  &[disabled] {
    background-color: var(--red-dark-disabled);
    border-color: var(--red-dark-disabled);
  }

  &.button--light {
    &[disabled] {
      background-color: var(--red-light-disabled);
      border-color: var(--red-light-disabled);
    }
  }
}

a.button--outlined,
.button--outlined {
  --color: var(--white);

  border-color: var(--brown-lighter);
  background-color: transparent;

  color: var(--color);

  &:visited {
    --color: var(--white);
  }

  &:hover {
    --color: var(--red-basic);

    border-color: var(--red-basic);
  }

  &[disabled] {
    --color: var(--brown-light);

    border-color: var(--brown-light);
  }

  &.button--light {
    --color: var(--black);

    border-color: var(--black);

    &:visited {
      --color: var(--black);
    }

    &:hover {
      --color: var(--red-dark);

      border-color: var(--red-dark);
    }

    &[disabled] {
      --color: var(--gray-light);

      border-color: var(--gray-light);
    }
  }
}

.button__text-wrapper {
  opacity: 0;
}

.button__loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1 / 1;

  svg {
    transform-origin: center;
    animation: rotate 0.75s infinite linear;
  }
}

.button--success .button__loader svg {
  animation: none;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
