import { defineStore } from 'pinia';
import { stringify } from 'qs';
import { useAuthStore } from './auth';
import {
  DocumentClass,
  GeneralDocumentType,
  type ClientDocument,
  ClientDocumentType,
  type GeneralDocument,
  type RequiredDocumentStatusModel,
} from '~/models/documents';
import { useApiFetch } from '~/composables/useApiFetch';
import { type SimpleStatusModel, type LinkModel } from '~/models/arbitrary';

type DocumentsCache = {
  [key in 'cs' | 'en']: { loaded: boolean; data: GeneralDocument[] };
};

const IMPORTANT_OTHER_DOCUMENTS: readonly GeneralDocumentType[] = [
  GeneralDocumentType.InformationOnInvestmentsServices,
  GeneralDocumentType.InformationOnSecuritiesFund,
  GeneralDocumentType.StrategyImplementationGuidelines,
  GeneralDocumentType.CustomersCategorization,
  GeneralDocumentType.MeasuresToAvoidConflicts,
  GeneralDocumentType.PrivacyPolicy,
  GeneralDocumentType.TermsOfUse,
] as const;
const IMPORTANT_DOCUMENTS: readonly GeneralDocumentType[] = [
  GeneralDocumentType.TermsAndConditions,
  GeneralDocumentType.PriceList,
  GeneralDocumentType.PortuBankAccount,
  GeneralDocumentType.ComplaintsProcedure,
  GeneralDocumentType.Agreement,
  GeneralDocumentType.GalleryProspect,
];

export const useDocumentsStore = defineStore('documents', {
  state: () => ({
    loadingGeneralDocuments: true,
    generalDocumentsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as DocumentsCache,
    importantGeneralDocumentsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as DocumentsCache,
    importantOtherGeneralDocumentsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as DocumentsCache,
    archivedGeneralDocumentsCache: {
      cs: { loaded: false, data: [] },
      en: { loaded: false, data: [] },
    } as DocumentsCache,
    loadingClientDocumentsStatus: true,
    clientDocumentsStatusCache: { loaded: false, data: [] as ClientDocument[] },
  }),
  getters: {
    generalDocuments: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (
        state.generalDocumentsCache[language].loaded &&
        state.generalDocumentsCache[language].data
      ) {
        return state.generalDocumentsCache[language].data;
      }

      return [];
    },
    importantGeneralDocuments: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.importantGeneralDocumentsCache[language].loaded) {
        return state.importantGeneralDocumentsCache[language].data;
      }

      return [];
    },
    importantOtherGeneralDocuments: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.importantOtherGeneralDocumentsCache[language].loaded) {
        return state.importantOtherGeneralDocumentsCache[language].data;
      }

      return [];
    },
    archivedGeneralDocuments: (state) => {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';

      if (state.archivedGeneralDocumentsCache[language].loaded) {
        return state.archivedGeneralDocumentsCache[language].data;
      }

      return [];
    },
    statementsAndConfirmations: (state) => {
      return state.clientDocumentsStatusCache.data
        .filter(
          (document) =>
            (document.Type === ClientDocumentType.Statement ||
              document.Type === ClientDocumentType.YearlyStatement ||
              document.Type === ClientDocumentType.QuarterlyStatement ||
              document.Type === ClientDocumentType.ConfirmationStatement ||
              document.Type === ClientDocumentType.TaxStatement) &&
            document.FileFormat === 'pdf',
        )
        .map((document) => {
          const csvDocument = state.clientDocumentsStatusCache.data.find(
            (clientDocument) =>
              clientDocument.Name === document.Name && clientDocument.FileFormat === 'csv',
          );

          return {
            ...document,
            HasCsvFile: !!csvDocument,
            CsvDocumentId: csvDocument ? csvDocument.Id : null,
          };
        });
    },
    clientDocuments: (state) => {
      const { compareDesc } = useDateFns();

      return state.clientDocumentsStatusCache.data.toSorted((a, b) =>
        compareDesc(new Date(a.CreatedOn), new Date(b.CreatedOn)),
      );
    },
    identifications: (state) => {
      const { compareDesc } = useDateFns();

      return state.clientDocumentsStatusCache.data
        .filter((document) => document.Type === ClientDocumentType.Identity)
        .toSorted((a, b) => compareDesc(new Date(a.CreatedOn), new Date(b.CreatedOn)));
    },
  },
  actions: {
    async getGeneralDocuments() {
      const {
        $i18n: { locale },
      } = useNuxtApp();
      const language = locale.value as 'cs' | 'en';
      const fetch = useApiFetch();

      if (this.generalDocumentsCache[language].loaded) {
        return this.generalDocumentsCache[language].data;
      }

      this.loadingGeneralDocuments = true;

      const documents = await fetch<GeneralDocument[]>(`/documents/generaldocuments/${language}`);

      const importantDocuments = documents.filter(
        (document) =>
          (document.ValidTo === null || document.ValidTo === undefined) &&
          IMPORTANT_DOCUMENTS.includes(document.DocumentType),
      );
      const importantOtherDocuments = documents.filter(
        (document) =>
          (document.ValidTo === null || document.ValidTo === undefined) &&
          IMPORTANT_OTHER_DOCUMENTS.includes(document.DocumentType),
      );
      const archivedDocuments = documents.filter(
        (document) =>
          document.ValidTo !== null &&
          document.ValidTo !== undefined &&
          new Date(document.ValidTo) < new Date(),
      );

      this.generalDocumentsCache[language] = {
        loaded: true,
        data: documents,
      };
      this.importantGeneralDocumentsCache[language] = {
        loaded: true,
        data: importantDocuments,
      };
      this.importantOtherGeneralDocumentsCache[language] = {
        loaded: true,
        data: importantOtherDocuments,
      };
      this.archivedGeneralDocumentsCache[language] = {
        loaded: true,
        data: archivedDocuments,
      };

      this.loadingGeneralDocuments = false;
    },
    async getClientDocumentsStatus({ force = false }: { force?: boolean } = {}) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      if (!force && this.clientDocumentsStatusCache.loaded) {
        return this.clientDocumentsStatusCache.data;
      }

      this.loadingClientDocumentsStatus = true;

      const response = await fetch<ClientDocument[]>(
        `documents/client/${authStore.user.Id}/status`,
      );

      this.clientDocumentsStatusCache.data = response;
      this.clientDocumentsStatusCache.loaded = true;
      this.loadingClientDocumentsStatus = false;
    },
    async getClientDocumentDownloadLink({ documentId }: { documentId: string }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return await fetch<LinkModel>(`documents/client/${authStore.user.Id}/link/${documentId}`);
    },
    async getClientDocuments({ classes }: { classes: DocumentClass[] }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return await fetch<ClientDocument[]>(
        `/documents/client/${authStore.user.Id}/documents${stringify(
          { classes },
          { skipNulls: true, addQueryPrefix: true, indices: false },
        )}`,
      );
    },
    async getDocumentStream({ documentId }: { documentId: string }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      try {
        let contentType = '';

        const response = await fetch<string>(
          `/documents/download/${authStore.user.Id}/${documentId}`,
          {
            responseType: 'text',
            onResponse({ response }) {
              if (response.status !== 200) {
                return;
              }

              contentType = response.headers.get('content-type') ?? '';
            },
          },
        );

        return {
          Base64: response,
          MimeType: contentType,
        };
      } catch (error) {
        return null;
      }
    },
    async uploadDocument({ formData }: { formData: FormData }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      const response = await fetch<ClientDocument[]>(
        `/clients/${authStore.user.Id}/uploaddocuments`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response.length > 0) {
        this.clientDocumentsStatusCache.data.push(...response);
      }

      return response;
    },
    async removeDocument({ documentId }: { documentId: string }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      const response = await fetch<SimpleStatusModel>(
        `/documents/client/${authStore.user.Id}/deleteidentitydocument/${documentId}`,
        { method: 'POST' },
      );

      if (response.Status) {
        this.clientDocumentsStatusCache.data.splice(
          this.clientDocumentsStatusCache.data.findIndex((document) => document.Id === documentId),
          1,
        );
      }

      return response;
    },
    async getDeclarationPdf({ taxResidency }: { taxResidency: string }) {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const {
        public: { hostUrl },
      } = useRuntimeConfig();

      return await fetch<LinkModel>(
        `${hostUrl}/documents/declaration/link/${taxResidency}/${authStore.user.Id}/Declaration.pdf`,
      );
    },
    async getContractAgreementPdf() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();
      const {
        public: { hostUrl },
      } = useRuntimeConfig();

      return await fetch<LinkModel>(
        `${hostUrl}/documents/contract/link/${authStore.user.Id}/Agreement.pdf`,
      );
    },
    async getRequiredAmendmentDocument() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      const {
        public: { productId },
      } = useRuntimeConfig();

      return await fetch<RequiredDocumentStatusModel>(
        `/documents/client/${productId}/requiredamendmentdocument/${authStore.user.Id}`,
      );
    },
    async getRequiredContractAmendmentDocument() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return await fetch<LinkModel>(`/documents/amendment/link/${authStore.user.Id}/Dodatek.pdf`);
    },
    async getCzechPointIdentityVerificationDownloadPdf() {
      const authStore = useAuthStore();
      const fetch = useApiFetch();

      return (
        await fetch<LinkModel>(
          `/clients/documents/link/${authStore.user.Id}/IndirectIdentification/Identifikace.pdf`,
        )
      ).Link;
    },
    _resetClientCache() {
      this.clientDocumentsStatusCache = { loaded: false, data: [] };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDocumentsStore, import.meta.hot));
}
