import { ValidationResult } from './types';
import { getRealMonth, getYearWithHundreds } from '../helpers/czech';

const validateDayAndMonth = (
  identityNumber: string,
  year: number,
  month: number,
  day: number,
): ValidationResult => {
  year = getYearWithHundreds(identityNumber, year);
  month = getRealMonth(year, month);

  if (month === 0 || month > 12) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.invalid-month',
    };
  }

  if (day === 0) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.invalid-day',
    };
  }

  // To understand a next line one must understand how dates in JS work.
  // The next line is using 2 rules:
  //   1. months are 0-indexed;
  //   2. using 0 as day JS will give last day of previous month;
  // By these rules there is no arithmetic subtraction of 1 from "month" variable.
  const targetDate = new Date(year, month, 0);
  const daysInTargetMonth = targetDate.getDate();

  if (daysInTargetMonth < day) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.invalid-day',
    };
  }

  return { isValid: true };
};

export const czechValidator = (identityNumber: string): ValidationResult => {
  if (!/^\d+$/.test(identityNumber)) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.wrong-number',
    };
  }

  if (identityNumber.length < 9 || identityNumber.length > 10) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.short-number',
    };
  }

  const year = parseInt(identityNumber.substring(0, 2), 10);
  const month = parseInt(identityNumber.substring(2, 4), 10);
  const day = parseInt(identityNumber.substring(4, 6), 10);

  if (identityNumber.length === 9 && year >= 54) {
    return {
      isValid: false,
      errorReason: 'common.registration.error.identity-number',
    };
  }

  if (identityNumber.length === 10) {
    const mod = parseInt(identityNumber.substring(0, 9), 10) % 11;
    const lastDigit = parseInt(identityNumber.substring(9), 10);

    if (mod === 10) {
      if (lastDigit !== 0) {
        return {
          isValid: false,
          errorReason: 'common.registration.error.identity-number',
        };
      }
    } else if (lastDigit !== mod) {
      return {
        isValid: false,
        errorReason: 'common.registration.error.identity-number',
      };
    }
  }

  return validateDayAndMonth(identityNumber, year, month, day);
};
